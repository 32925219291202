import React from 'react';

import { Box, Flex } from '../../../core';
import { stopPropagation } from '../utils';

import { Props as RadioFieldOptionProps } from './RadioFieldOption';

export type Props = Pick<
  RadioFieldOptionProps,
  'fieldProps' | 'value' | 'id' | 'disabled' | 'required'
> & {
  describedBy?: string;
  isSelected: boolean;
};

const RadioFieldOptionDot = ({
  describedBy,
  disabled,
  fieldProps,
  id,
  required,
  value,
  isSelected,
}: Props): JSX.Element => {
  return (
    <Flex flexShrink="0">
      {/* actual input is accessibly hidden */}
      <Box
        {...fieldProps}
        aria-describedby={describedBy}
        aria-disabled={disabled}
        aria-required={required}
        // for proper screenreader+keyboard a11y, disabled radiofields need:
        //   - the selected radio to be readOnly=true & disabled=false
        //   - all unselected radios to be disabled=true
        readOnly={disabled}
        disabled={isSelected ? false : disabled}
        as="input"
        type="radio"
        value={value}
        id={id}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          zIndex: -1000,
        }}
        onClick={stopPropagation} // prevents calling the wrapper click handler when this element is clicked
      />

      {/* fake radio button comes second so we can use sibling (+) selectors to style based on input state */}
      {/* outer Box is the ring that wraps the dot, border color controls ring color, background the inner part */}
      <Box
        width="6x"
        height="6x"
        p="1x"
        border="2px solid"
        borderRadius="round"
        borderColor={disabled ? 'interactive_border.disabled_base' : 'border.primary'}
        background={disabled ? 'interactive.disabled_base' : 'primary'}
        sx={{
          /** checked input styles, the '&&' increases the priority of these rules */
          'input:checked + &&': {
            borderColor: disabled
              ? 'interactive_border.disabled_base'
              : 'interactive_border.primary_active',
            background: disabled ? 'primary' : 'interactive.disabled_base',
            // dot styles
            '> div': {
              background: disabled ? 'tertiary' : 'contrast',
            },
          },
        }}
      >
        {/* inner Box is the dot in the center */}
        <Box
          height="12px"
          width="12px"
          borderRadius="round"
          background={disabled ? 'interactive.disabled_base' : 'primary'}
        />
      </Box>
    </Flex>
  );
};

export default RadioFieldOptionDot;
