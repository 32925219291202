import React, { useEffect, useId, useMemo, useState } from 'react';

import { Flex, Text } from '@rover/kibble/core';
import { DSTokenMap, Spacing } from '@rover/kibble/styles';

import FormBasicValidationError from '../FormValidationError/FormBasicValidationError';

export interface TextFieldProps {
  primaryLabel?: React.ReactNode;
  id?: string;
  secondaryLabel?: React.ReactNode;
  dataTestId?: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  helperMessage?: React.ReactNode;
  errorMessage?: string;
  rightItem?: React.ReactNode;
  rightItemSpacing?: string;
  rightItemClick?: () => void;
  leftItem?: React.ReactNode;
  disabled?: boolean;
  /**
   * @deprecated
   * The `onClick` method for TextField is deprecated and will be removed.
   * Its use breaks accessibility and is not recommended.
   * Use a Button instead.
   * */
  onClick?: () => void;
  readOnly?: boolean;
  ariaLabelledby?: string;
}
const getInputColor = (disabled?: boolean, inputHasValue?: boolean): string => {
  if (disabled) return DSTokenMap.TEXT_COLOR_DISABLED.toString();
  if (inputHasValue) return DSTokenMap.TEXT_COLOR_PRIMARY.toString();
  return DSTokenMap.TEXT_COLOR_PLACEHOLDER.toString();
};

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      primaryLabel,
      id,
      secondaryLabel,
      value,
      onChange,
      onClick,
      placeholder,
      inputProps,
      helperMessage,
      errorMessage,
      rightItem,
      rightItemSpacing,
      rightItemClick,
      leftItem,
      disabled,
      readOnly,
      dataTestId,
    },
    ref
  ): JSX.Element => {
    const staticId = useId();
    const inputId = useMemo(() => id ?? staticId, [id, staticId]);
    const [inputHasValue, setInputHasValue] = useState(!!value && value !== '');

    // we use this function to cover the cases where the value is an empty string and when the type === button
    const useValueAsPlaceholder = useMemo(
      () => value === '' || (!inputHasValue && inputProps?.type === 'button'),
      [inputHasValue, inputProps, value]
    );

    useEffect(() => {
      if (onClick) {
        // eslint-disable-next-line no-console
        console.warn(
          `TextField with id ${inputId} has an onClick handler. This is deprecated and will be removed. Use a Button instead.`
        );
      }
    }, [onClick, inputId]);

    useEffect(() => {
      setInputHasValue(!!value && value !== '');
    }, [value]);

    return (
      <Flex flexDirection="column" data-testid={dataTestId}>
        {typeof primaryLabel === 'string' ? (
          <Text as="label" htmlFor={inputId} fontWeight="semibold" mb="1x">
            {primaryLabel}
          </Text>
        ) : (
          primaryLabel
        )}
        {typeof secondaryLabel === 'string' ? (
          <Text mb="1x" textColor="tertiary">
            {secondaryLabel}
          </Text>
        ) : (
          secondaryLabel
        )}
        <Flex flexDirection="row" alignItems="center" position="relative">
          {leftItem && (
            <Flex
              alignItems="center"
              position="absolute"
              left="10px" // 10px = 8px (Spacing.S) + 2px (borderWidth)
            >
              {leftItem}
            </Flex>
          )}
          <Flex flexDirection="row" flexGrow="1" {...(onClick && { role: 'button', onClick })}>
            <input
              data-testid="textfield-input"
              ref={ref}
              id={inputId}
              value={useValueAsPlaceholder ? placeholder : value}
              disabled={disabled}
              type="text"
              onChange={(e) => onChange?.(e.target.value)}
              placeholder={placeholder}
              {...inputProps}
              style={{
                minHeight: DSTokenMap.SPACE_12X,
                paddingTop: DSTokenMap.SPACE_3X,
                paddingBottom: DSTokenMap.SPACE_3X,
                paddingLeft: leftItem ? '40px' : DSTokenMap.SPACE_3X,
                paddingRight: rightItem ? '40px' : Spacing.S.toString(), // 40px = iconWidth (24px) + 2x Spacing.S (16px)
                outlineOffset: DSTokenMap.BORDER_WIDTH_PRIMARY,
                lineHeight: DSTokenMap.TEXT_100_LINE_HEIGHT,
                border: `${DSTokenMap.BORDER_WIDTH_PRIMARY} solid`,
                borderColor: DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY.toString(),
                fontSize: DSTokenMap.TEXT_200_FONT_SIZE.toString(),
                borderRadius: DSTokenMap.BORDER_RADIUS_SECONDARY,
                ...(disabled && {
                  borderColor: DSTokenMap.INTERACTIVE_BORDER_COLOR_DISABLED.toString(),
                }),
                color: getInputColor(disabled, inputHasValue),
                ...(errorMessage &&
                  !disabled && {
                    borderColor: DSTokenMap.BORDER_COLOR_ERROR.toString(),
                  }),
                width: '100%',
                ...inputProps?.style,
              }}
              readOnly={readOnly}
            />
          </Flex>
          {rightItem && (
            <Flex
              alignItems="center"
              position="absolute"
              right={rightItemSpacing || Spacing.S.toString()}
              onClick={rightItemClick}
            >
              {rightItem}
            </Flex>
          )}
        </Flex>
        {helperMessage &&
          !errorMessage &&
          (typeof helperMessage === 'string' ? (
            <Text size="100" textColor="tertiary" mt="1x">
              {helperMessage}
            </Text>
          ) : (
            helperMessage
          ))}
        {errorMessage && (
          <FormBasicValidationError
            errorMessage={errorMessage}
            boxProps={{ mt: '1x' }}
            errorMessageArialive="assertive"
          />
        )}
      </Flex>
    );
  }
);

export default TextField;
