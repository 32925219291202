import styled from 'styled-components';

import { Color, DSTokenMap, MQ, ZIndex } from '@rover/kibble/styles';

type DatePickerStyleWrapperProps = {
  hasError?: boolean;
};

export const DatePickerStyleWrapper = styled.div<DatePickerStyleWrapperProps>`
  position: relative;

  input {
    height: 40px;
    width: 100%;
    border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY};
    padding: ${DSTokenMap.SPACE_2X};
    transition: border-color ease-in-out 0.15s;
    cursor: pointer;
    border: ${DSTokenMap.BORDER_WIDTH_PRIMARY} solid
      ${(props): string =>
        props.hasError
          ? DSTokenMap.BORDER_COLOR_ERROR.toString()
          : DSTokenMap.BORDER_COLOR_PRIMARY.toString()};

    &:focus {
      border-color: ${DSTokenMap.BORDER_COLOR_INPUT_FOCUS.toString()};

      ${MQ.XS_DOWN.toString()} {
        font-size: 16px;
      }
    }

    &:disabled {
      font-weight: 500;
      opacity: 1;
    }
  }

  .DayPickerInput {
    width: 100%;

    &-OverlayWrapper {
      top: ${DSTokenMap.SPACE_6X.toString()};
    }

    &-Overlay {
      width: 100%;
      z-index: ${ZIndex.DROPDOWN.toString()};
      position: absolute;
      border-radius: 4px;
      border: ${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid
        ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()};

      ${MQ.XS_DOWN.toString()} {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: ${DSTokenMap.BACKGROUND_COLOR_OVERLAY.toString()};
        border: none;
        border-radius: 0;
      }
    }

    ${MQ.XS_DOWN.toString()} {
      &-Overlay-Container {
        position: relative;
        background: ${Color.NEUTRAL_WHITE.toString()};
        border-radius: 16px 16px 0 0;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
      }
    }
  }

  .DayPicker {
    width: 100%;

    ${MQ.SM_UP.toString()} {
      &-Caption {
        padding: 0 ${DSTokenMap.SPACE_4X};
      }
    }

    &-Caption > div {
      color: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
      font-size: ${DSTokenMap.HEADING_200_FONT_SIZE.toString()};
      font-weight: ${DSTokenMap.HEADING_200_FONT_WEIGHT.toString()};
      text-transform: capitalize;
    }

    &-Month {
      width: 100%;
      border-collapse: unset;
      border-spacing: 1px;
      margin: 0 16px;
    }

    &-Weekday {
      color: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
      font-size: ${DSTokenMap.HEADING_100_FONT_SIZE.toString()};
    }

    &-Day {
      border-radius: 0;
      height: 40px;
      width: 40px;
      min-width: 40px;
      color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
      font-size: ${DSTokenMap.TEXT_100_FONT_SIZE.toString()};
      font-weight: ${DSTokenMap.HEADING_200_FONT_WEIGHT.toString()};
      position: relative;

      ${MQ.XS_DOWN.toString()} {
        height: 50px;
        width: 50px;
        min-width: 50px;
      }

      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(
          .DayPicker-Day--between
        ):hover {
        border-radius: ${DSTokenMap.SPACE_2X.toString()};
      }

      &--start {
        &:after {
          background-color: ${Color.GREEN_200.toString()} !important;
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: -1;
        }
        &.DayPicker-Day--selected {
          &:after {
            left: 50%;
          }
        }
      }

      &--end {
        &:before {
          background-color: ${Color.GREEN_200.toString()} !important;
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: -1;
        }
        &.DayPicker-Day--selected {
          &:before {
            right: 50%;
          }
        }
      }

      &--selected {
        &:not(.DayPicker-Day--outside) {
          background-color: ${Color.NEUTRAL_WHITE.toString()};
          color: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
          border: ${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid
            ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE.toString()};
        }
        &:not(.DayPicker-Day--between):not(.DayPicker-Day--disabled) {
          border-radius: 8px;
          &.DayPicker-Day--between {
            background-color: ${Color.GREEN_200.toString()};
            color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
            &:hover {
              border-radius: 0;
              background-color: ${Color.NEUTRAL_WHITE.toString()};
              color: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
            }
          }
        }
      }

      &--disabled {
        color: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_DISABLED.toString()} !important;
      }
    }
  }
`;
