/* This component should subtitute BirthdayDatePicker once DEV-95888 is done */
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { t } from '@lingui/macro';
import moment from 'moment';

import LabelAndErrorFormGroup from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import 'react-day-picker/lib/style.css';

import { BirthdayDatePickerProps, StyledIcon as CalendarIcon } from '../GenericCalendar';

import { ERROR_TYPES, OverlayContextProvider } from './BirthdayDatePickerContext';
import { Overlay } from './BirthdayDatePickerOverlay';
import { DatePickerStyleWrapper } from './DatePickerStyleWrapper';
import { MonthYearNavbar } from './MonthYearNavbar';

const getInitialDate = (maxDate?: Date): { month: number; year: number } => {
  const today = moment();

  if (!maxDate || today.isBefore(moment(maxDate))) {
    return { month: today.month(), year: today.year() };
  }

  return { month: moment(maxDate).month(), year: moment(maxDate).year() };
};

const BirthdayDatePickerDesktopGeneric: FC<BirthdayDatePickerProps> = ({
  placeholder,
  minDate,
  maxDate,
  name,
  value,
  onBlur,
  onChange,
  validationError,
  renderValidationError,
  ...props
}) => {
  const ref = useRef<DayPickerInput>(null);
  const { locale, DF, i18n } = useI18n();
  const [month, setMonth] = useState(() => value?.getMonth?.() ?? getInitialDate(maxDate).month);
  const [year, setYear] = useState(() => value?.getFullYear?.() ?? getInitialDate(maxDate).year);
  const [error, setError] = useState(ERROR_TYPES.NO_ERROR);

  const weekdays = useMemo(
    () =>
      moment
        .localeData(locale)
        .weekdays()
        .map((weekday) => weekday[0].toUpperCase()),
    []
  );

  const checkInputFormat = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const dateString = e.target.value;
      if (dateString) {
        const dayMonthRegex = /^(0?[1-9]|[12][0-9]|3[01])([/.-])(0?[1-9]|1[012])\2(\d{4})$/;
        const monthDayRegex = /^(0?[1-9]|1[012])([/.-])(0?[1-9]|[12][0-9]|3[01])\2(\d{4})$/;
        const isAmericanDateFormat = DF.MONTH_DAY_SHORT === 'MM/DD';
        const regex = isAmericanDateFormat ? monthDayRegex : dayMonthRegex;
        const match = dateString.match(regex);

        if (match) {
          const day = isAmericanDateFormat ? match[3] : match[1];
          const m = isAmericanDateFormat ? match[1] : match[3];
          const y = match[4];
          setError(ERROR_TYPES.NO_ERROR);
          onChange(
            moment()
              .date(+day)
              .month(+m - 1)
              .year(+y)
              .toDate()
          );
        } else {
          setError(ERROR_TYPES.INVALID_FORMAT);
        }
      }
    },
    [DF.MONTH_DAY_SHORT]
  );

  const clearError = useCallback(() => {
    setError(ERROR_TYPES.NO_ERROR);
  }, []);

  const inputProps = useMemo(
    () => ({
      name,
      'data-testid': 'birthday-date-picker-desktop',
      'data-qa-id': 'input-birthday-datepicker',
      'aria-label': i18n._(
        /* i18n: The var here will be a localized date string format, ex: 'date input in MM/DD/YYYY format' */
        t`date input in ${DF.DATE_SHORT} format`
      ),
      onFocus: clearError,
      onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
        checkInputFormat(e);
        onBlur?.();
      },
    }),
    [clearError, checkInputFormat, onBlur, name]
  );

  const handleMonth = useCallback(
    (newMonth) => {
      setMonth(newMonth);
      onChange(moment(value).month(newMonth).toDate());
      ref?.current?.getInput().focus();
    },
    [value]
  );

  const handleYear = useCallback(
    (newYear) => {
      setYear(newYear);
      onChange(moment(value).year(newYear).toDate());
      ref?.current?.getInput().focus();
    },
    [value]
  );

  const dayPickerProps = {
    fromMonth: minDate,
    toMonth: maxDate,
    month: new Date(year, month),
    disabledDays: [
      {
        before: minDate,
        after: maxDate,
      },
    ],
    weekdaysShort: weekdays,
    locale,
    localeUtils: MomentLocaleUtils,
    navbarElement: (
      <MonthYearNavbar
        minDate={minDate}
        maxDate={maxDate}
        selectedMonth={month}
        selectedYear={year}
        setMonth={handleMonth}
        setYear={handleYear}
      />
    ),
    onDayClick: () => {
      if (error) {
        setError(ERROR_TYPES.NO_ERROR);
      }
    },
  };

  const handleClose = useCallback(() => {
    ref?.current?.hideDayPicker();
  }, []);

  const contextState = useMemo(
    () => ({ error, setError, handleClose }),
    [error, setError, handleClose]
  );

  const isInvalidFormat = error === ERROR_TYPES.INVALID_FORMAT;
  const invalidFormatError = isInvalidFormat ? i18n._(t`Use ${DF.DATE_SHORT} format`) : undefined;

  const format = useMemo(
    () => [
      DF.DATE_SHORT,
      DF.DATE_SHORT?.replace(/[/.-]/g, '/'),
      DF.DATE_SHORT?.replace(/[/.-]/g, '-'),
      DF.DATE_SHORT?.replace(/[/.-]/g, '.'),
    ],
    [DF.DATE_SHORT]
  );

  const currentValidationError = useMemo(
    () => renderValidationError?.(invalidFormatError) || validationError || invalidFormatError,
    [renderValidationError, invalidFormatError, validationError]
  );

  return (
    <OverlayContextProvider value={contextState}>
      <LabelAndErrorFormGroup
        {...props}
        validationType="inline"
        validationError={currentValidationError}
      >
        {(): JSX.Element => (
          <DatePickerStyleWrapper hasError={!!currentValidationError}>
            <DayPickerInput
              ref={ref}
              value={value}
              placeholder={placeholder || DF.DATE_SHORT}
              inputProps={inputProps}
              dayPickerProps={dayPickerProps}
              onDayChange={(date) => {
                onChange(date);
                if (date) {
                  setMonth(date.getMonth());
                  setYear(date.getFullYear());
                }
              }}
              format={format}
              formatDate={MomentLocaleUtils.formatDate}
              parseDate={MomentLocaleUtils.parseDate}
              overlayComponent={Overlay}
              hideOnDayClick
              keepFocus={false}
            />
            {!value && <CalendarIcon />}
          </DatePickerStyleWrapper>
        )}
      </LabelAndErrorFormGroup>
    </OverlayContextProvider>
  );
};

export default BirthdayDatePickerDesktopGeneric;
