import { I18n, MessageDescriptor } from '@lingui/core';
import moment from 'moment';

import { ServiceSchedulerInfo } from '@rover/react-lib/src/components/datetime/ServiceScheduler/types';
import SERVICES, { ServiceDescriptorType } from '@rover/react-lib/src/constants/services.constants';
import dayFactory, { createDayFromDate } from '@rover/react-lib/src/factories/dayFactory';
import getDateTimeFormatMapForLang from '@rover/shared/js/constants/i18n/datetime';
import type { Day } from '@rover/types/src/datetime/Day';
import { SelectOption } from '@rover/types/src/SelectOption';
import { ServiceFrequencyEnum } from '@rover/types/src/ServiceFrequency';

const getAvailableServices = (availableServices: string[]): ServiceDescriptorType[] =>
  SERVICES.filter((s) => availableServices.includes(s.type));

export const getAvailableServiceOptions = (availableServices: string[]): SelectOption[] =>
  getAvailableServices(availableServices).map((svc) => ({
    ...svc,
    icon: svc.iconComponent,
    value: svc.type,
    'data-qa-id': `button-${svc.type}`,
  }));
export const getFrequencyFromBoolean = (value: boolean): ServiceFrequencyEnum =>
  value ? ServiceFrequencyEnum.REPEAT : ServiceFrequencyEnum.ONE_TIME;

export const getBooleanFromFrequency = (frequency: ServiceFrequencyEnum): boolean =>
  frequency === ServiceFrequencyEnum.REPEAT;

export const getInitialDate = (dates: Day[]): Date =>
  new Date(
    Math.min.apply(
      null,
      dates.map((date) => (date.date ? date.date.valueOf() : 0))
    )
  );

export const getInitialMonth = (serviceSchedulerInfo: ServiceSchedulerInfo): Date => {
  if (serviceSchedulerInfo.selectedDates.length) {
    return getInitialDate(serviceSchedulerInfo.selectedDates);
  }

  if (serviceSchedulerInfo.startDate) {
    return serviceSchedulerInfo.startDate;
  }

  return new Date();
};

export const getMonthPosition = (endMonth: Date | undefined | null): number => {
  if (!endMonth) {
    return 0;
  }

  const selectedMonth = endMonth.getMonth();
  const selectedYear = endMonth.getFullYear();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  return Math.max((selectedYear - currentYear) * 12 - currentMonth + selectedMonth + 1, 0);
};

// Used when the same day is selected as start and end dates. 1 day in Boarding/House Sitting bookings.
export const getRepeatedDaysSelected = (startDate, endDate, selectedDates): Day[] => {
  if (selectedDates.length === 1 && startDate && endDate) {
    if (moment(startDate).isSame(moment(endDate), 'day')) {
      return [selectedDates[0], selectedDates[0]];
    }
  } else if (!(startDate || endDate)) {
    return [];
  }
  return selectedDates;
};

export const getTranslatedString = (i18n: I18n, value?: string | MessageDescriptor): string => {
  if (!value) {
    return '';
  }
  return typeof value === 'string' ? value : i18n._(value);
};

export const getNewStartDate = (
  date: Date | undefined,
  language: string,
  serviceSchedulerInfo: ServiceSchedulerInfo
): Date => {
  const startDate = date || new Date();
  const startDay = createDayFromDate(startDate, language);
  const DF = getDateTimeFormatMapForLang(language);
  const dayName = moment(startDate).format(DF.WEEKDAY_FULL);
  const dayAlreadyExists = serviceSchedulerInfo.selectedDays.find((day) => day.name === dayName);
  if (!dayAlreadyExists) {
    serviceSchedulerInfo.selectedDays.push(
      dayFactory({
        id: moment(startDate).isoWeekday() % 7,
        name: dayName,
        date: startDate,
      })
    );
  }

  const dateAlreadyExists = serviceSchedulerInfo.selectedDates.filter((selectedDate) => {
    return moment(selectedDate.date).isSame(moment(startDate), 'd');
  });

  if (!dateAlreadyExists) {
    serviceSchedulerInfo.selectedDates.unshift(startDay);
  }
  return startDate;
};
