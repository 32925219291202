import { BirthdayDatePickerProps } from '../GenericCalendar';

import BirthdayDatePickerDesktopGeneric from './BirthdayDatePickerDesktopGeneric';
import BirthdayDatePickerMobileGeneric from './BirthdayDatePickerMobileGeneric';

const BirthdayDatePickerGeneric: React.FC<BirthdayDatePickerProps> = (
  props: BirthdayDatePickerProps
) => {
  if (props.isMobile) {
    return <BirthdayDatePickerMobileGeneric {...props} />;
  }
  return <BirthdayDatePickerDesktopGeneric {...props} />;
};

export default BirthdayDatePickerGeneric;
