import React, { ReactNode, useEffect, useMemo, useRef } from 'react';

import { Box } from '@rover/kibble/core';
import { getMonthPosition } from '@rover/react-lib/src/pages/contact-sitter/ContactSitterPage/components/ContactSitterForm/components/ServiceScheduler/utils';

export type ScrollableDayPickerProp = {
  initialMonth: Date | undefined | null;
  wrapperStyles: {
    overflow: string;
    height?: string;
    width?: string;
  };
  children: ReactNode;
};

const ScrollableDayPicker = ({
  initialMonth,
  wrapperStyles,
  children,
}: ScrollableDayPickerProp): JSX.Element => {
  const dayPickerRef = useRef<HTMLDivElement>(null);
  const initialMonthPosition = useMemo(() => getMonthPosition(initialMonth), [initialMonth]);

  useEffect(() => {
    if (!initialMonthPosition) {
      return;
    }
    setTimeout(() => {
      const monthsElements = dayPickerRef.current?.getElementsByClassName('DayPicker-Month');
      if (
        monthsElements &&
        monthsElements.length >= initialMonthPosition &&
        initialMonthPosition > 1
      ) {
        monthsElements[initialMonthPosition - 1].scrollIntoView();
      }
    }, 1);
  }, [initialMonthPosition]);

  return (
    <Box sx={wrapperStyles} ref={dayPickerRef}>
      {children}
    </Box>
  );
};

export default ScrollableDayPicker;
