import React, { createContext, FC, useContext } from 'react';

export interface SchedulerFormContextValue {
  isDirty?: boolean;
  onFormDirtinessChanged?: (dirty: boolean) => void;
}

export const SchedulerFormContext = createContext<SchedulerFormContextValue>({});

export const useSchedulerForm = (): SchedulerFormContextValue => {
  const context = useContext(SchedulerFormContext);

  if (!context) {
    throw new Error('useSchedulerForm must be used within a SchedulerFormProvider');
  }

  return context;
};

export type SchedulerFormContextProps = {
  context: SchedulerFormContextValue;
  children: React.ReactNode;
};

export const SchedulerFormProvider: FC<SchedulerFormContextProps> = ({ children, context }) => {
  return <SchedulerFormContext.Provider value={context}>{children}</SchedulerFormContext.Provider>;
};
