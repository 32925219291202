/* eslint-disable rover/prefer-inline-validation */
import React, { FC, useCallback, useMemo } from 'react';
import { t } from '@lingui/macro';
import moment from 'moment';
import styled from 'styled-components';

import { SelectArrow } from '@rover/icons';
import { Box, Flex } from '@rover/kibble/core';
import { Select } from '@rover/react-lib/src/components/formFields';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 0; // override FormGroup margin-bottom

  select {
    appearance: none;
    padding: 8px 20px 8px 12px;
    text-transform: capitalize;
  }
`;

const StyledArrow = styled(SelectArrow)`
  position: absolute;
  top: 14px;
  right: 12px;
  pointer-events: none;
`;

type MonthYearNavbarProps = {
  minDate: Date;
  maxDate?: Date;
  selectedMonth: number;
  selectedYear: number;
  setYear: (y: number) => void;
  setMonth: (m: number) => void;
};

export const MonthYearNavbar: FC<MonthYearNavbarProps> = ({
  minDate,
  maxDate,
  selectedMonth,
  selectedYear,
  setYear,
  setMonth,
}) => {
  const { locale, i18n } = useI18n();

  const momentMinDate = moment(minDate);
  const momentMaxDate = moment(maxDate);

  const months = useMemo(
    () =>
      moment
        .localeData(locale)
        .months()
        .map((month, i) => ({
          title: month,
          value: i,
        }))
        .filter((month) => {
          if (+selectedYear === momentMinDate.year()) return month.value >= momentMinDate.month();
          if (+selectedYear === momentMaxDate.year()) return month.value <= momentMaxDate.month();
          return true;
        }),
    [locale, momentMaxDate, momentMinDate, selectedYear]
  );

  const years = useMemo(() => {
    const range = moment().year() - minDate.getFullYear() + 1;
    return [...Array(range).keys()]
      .map((i) => i + minDate.getFullYear())
      .map((y) => ({ title: `${y}`, value: y }));
  }, [minDate]);

  const handleYearChange = useCallback(
    (newYear: string | number) => {
      const maxMonth = momentMaxDate.month();
      const minMonth = momentMinDate.month();

      setYear(+newYear);

      if (+newYear === momentMinDate.year() && +selectedMonth < minMonth) {
        setMonth(minMonth);
        return;
      }
      if (+newYear === momentMaxDate.year() && +selectedMonth > maxMonth) {
        setMonth(maxMonth);
      }
    },
    [momentMaxDate, momentMinDate, selectedMonth, setMonth, setYear]
  );

  const handleMonthChange = useCallback(
    (newMonth: string | number) => setMonth(+newMonth),
    [setMonth]
  );

  return (
    <Flex justifyContent="center" gap="2x" py="6x" px="4x">
      <Box position="relative" flex="1">
        <StyledSelect
          data-qa-id="year-select"
          data-testid="year-select"
          aria-label={i18n._(t`Select a year`)}
          value={selectedYear}
          validationType="popover"
          options={years}
          onChange={handleYearChange}
        />
        <StyledArrow />
      </Box>
      <Box position="relative" flex="1">
        <StyledSelect
          data-qa-id="month-select"
          data-testid="month-select"
          aria-label={i18n._(t`Select a month`)}
          value={selectedMonth}
          validationType="popover"
          options={months}
          onChange={handleMonthChange}
        />
        <StyledArrow />
      </Box>
    </Flex>
  );
};
