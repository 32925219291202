import { createContext, useContext } from 'react';

export enum ERROR_TYPES {
  NO_ERROR = '',
  EMPTY = 'empty',
  INVALID_FORMAT = 'invalid format',
}

type OverlayContextType = {
  error: ERROR_TYPES;
  setError: (error: ERROR_TYPES) => void;
  handleClose: () => void;
};

const OverlayContext = createContext<OverlayContextType>({
  error: ERROR_TYPES.NO_ERROR,
  setError: () => {},
  handleClose: () => {},
});

export const OverlayContextProvider = OverlayContext.Provider;

export const useOverlayContext = (): OverlayContextType => useContext(OverlayContext);
