import {
  CatPlaceholder,
  DogPlaceholder,
  PawPlaceholder,
  PersonPlaceholder,
} from './Avatar.defaults';
import { AvatarSize, AvatarType } from './Avatar.types';

export const DEFAULT_IMAGE: Record<AvatarType, string> = {
  cat: CatPlaceholder,
  dog: DogPlaceholder,
  person: PersonPlaceholder,
  other: PawPlaceholder,
};

export const AVATAR_SIZE: Record<AvatarSize, number> = {
  small: 32,
  default: 48,
  xLarge: 64,
  xxLarge: 128,
};
