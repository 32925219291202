import React from 'react';
import { Modifiers } from 'react-day-picker';
import { MessageDescriptor } from '@lingui/core';
import moment from 'moment';
import styled from 'styled-components';

import { Calendar, Close } from '@rover/icons';
import { DSTokenMap, Spacing } from '@rover/kibble/styles';
import { Props as LabelAndErrorFormGroupProps } from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import { Day } from '@rover/types';

import BirthdayDatePicker from '../BirthdayDatePicker/BirthdayDatePickerGeneric';
import DatePicker, { CalendarDirection } from '../DatePicker/DatePickerGeneric';
import MultiDateAutoFillPicker from '../MultiDateAutoFillPicker/MultiDateAutoFillPickerGeneric';
import MultiDatePicker from '../MultiDatePicker/MultiDatePickerGeneric';

export enum CalendarType {
  Range,
  Multiple,
  Single,
}

interface GenericCalendarProps {
  calendarType?: CalendarType;
  ariaLabelledBy?: string;
}

type MultiSharedProps<T extends Day> = {
  className?: string;
  disableAfterDateTime?: Date;
  disableBeforeDateTime?: Date;
  holidays?: Date[];
  initialMonth?: Date;
  language: string;
  onChange: (days: T[]) => void;
  disabledDays?: Array<Date | ((day: Date) => boolean) | Record<string, unknown> | undefined>; // from DayPicker docs: Date | Object | Date[] | (day: Date) ⇒ boolean
  selectedDays: T[];
  newCalendarInteraction?: boolean;
  validationType: 'popover' | 'inline';
  maxDate?: Date | null;
  isMobileBrowser: boolean;
  isDismissible?: boolean;
};

export type MultiDateAutoFillPickerProps<T extends Day> = {
  showTwoInputs?: boolean;
  endDatePlaceholder?: string;
  startDatePlaceholder?: string;
  disableValidation?: boolean;
  inputStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  onClickFromSearch?: () => void;
  serviceName: MessageDescriptor | string;
} & LabelAndErrorFormGroupProps &
  MultiSharedProps<T>;

export type MultiDatePickerProps<T extends Day> = {
  serviceSubtitle?: MessageDescriptor | string;
  fromMonth?: Date;
  serviceName?: MessageDescriptor | string;
} & LabelAndErrorFormGroupProps &
  MultiSharedProps<T>;

export type DatePickerProps = Omit<LabelAndErrorFormGroupProps, 'placeholder'> & {
  allowKeyboardInput?: boolean;
  asCalendar?: boolean;
  calendarDirection?: CalendarDirection;
  className?: string;
  date?: Date | null;
  holidays?: Date[];
  id?: string;
  initialMonth?: Date;
  isDayBlocked?: (arg0: Date) => boolean;
  language: string;
  maxDate?: Date | null;
  minDate?: Date;
  modifiers?: Partial<Modifiers>;
  onChange: (arg0: Date | undefined) => void;
  placeholder?: string | MessageDescriptor;
  newCalendarInteraction?: boolean;
  serviceSubtitle?: string | MessageDescriptor;
  serviceName?: string | MessageDescriptor;
  disableValidation?: boolean;
  isMobileBrowser?: boolean;
  inputStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  onClickFromSearch?: () => void;
  validationType: 'popover' | 'inline';
  isDismissible?: boolean;
  isRequired?: boolean;
  syncInputWithDate?: boolean;
};

export type BirthdayDatePickerProps = LabelAndErrorFormGroupProps & {
  minDate: Date;
  maxDate?: Date;
  name?: string;
  value?: Date;
  onBlur?: () => void;
  onChange: (date: Date) => void;
  isMobile?: boolean;
  /** renderProp to use in place of `validationError` in case the internal error needs to be communicated to a parent component */
  renderValidationError?: (
    internalError?: string | MessageDescriptor | null
  ) => string | MessageDescriptor | null | undefined;
};

type ConditionalProps =
  | ({
      calendarType?: CalendarType.Range;
    } & MultiDateAutoFillPickerProps<Day>)
  | ({
      calendarType?: CalendarType.Multiple;
    } & MultiDatePickerProps<Day>)
  | ({
      calendarType?: CalendarType.Single;
    } & (
      | ({ showMonthAndYear: true } & BirthdayDatePickerProps)
      | ({ showMonthAndYear?: false } & DatePickerProps)
    ));

type ExclusiveAccessibilityProps =
  | { 'aria-label'?: string; 'aria-labelledby'?: never }
  | { 'aria-label'?: never; 'aria-labelledby'?: string };

type Props = GenericCalendarProps & ConditionalProps & ExclusiveAccessibilityProps;

export const numberOfMonthsAllowed = (maxDate: Date | undefined | null): number => {
  if (maxDate) {
    // Adding 2 to the result to count both first and last month
    return Math.abs(moment().diff(moment(maxDate), 'months')) + 2;
  }
  // Adding 2 to the result to count both first and last month
  return Math.abs(moment().diff(moment(getMaxRequestDate()), 'months')) + 2;
};

export const StyledIcon = styled(Calendar)`
  position: absolute;
  height: ${(props) => props.iconStyle?.height ?? '24px'};
  width: ${(props) => props.iconStyle?.width ?? '24px'};
  right: ${(props) => props.iconStyle?.right ?? Spacing.S.toString()};
  top: calc(50% - 12px);
  fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  pointer-events: none;
`;

export const StyledCloseIcon = styled(Close)`
  height: 16px;
  width: 16px;
  fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  pointer-events: none;
`;

const GenericCalendar = (props: Props): JSX.Element | null => {
  switch (props.calendarType) {
    case CalendarType.Range:
      return <MultiDateAutoFillPicker {...props} newCalendarInteraction />;
    case CalendarType.Multiple:
      return <MultiDatePicker {...props} newCalendarInteraction />;
    case CalendarType.Single:
      if (props.showMonthAndYear) {
        return <BirthdayDatePicker {...props} />;
      }
      return <DatePicker {...props} newCalendarInteraction />;
    default:
      return null;
  }
};

export default GenericCalendar;
