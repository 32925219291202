/* This component should subtitute DatePicker once DEV-95888 is done */
/* eslint-disable rover/no-platform-specific-globals-or-imports, max-classes-per-file */
import React, {
  forwardRef,
  Suspense,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import DayPicker from 'react-day-picker/DayPicker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import moment from 'moment';
import styled from 'styled-components';

import { Calendar, Close } from '@rover/icons';
import { Box, Button, Flex, Heading, Paragraph } from '@rover/kibble/core';
import BottomSheet from '@rover/kibble/official-patterns/BottomSheet';
import { A11yHiddenBox, DSTokenMap, MQ, Spacing } from '@rover/kibble/styles';
import ScrollableDayPicker from '@rover/react-lib/src/components/datetime/ScrollableDayPicker/ScrollableDayPicker';
import FormBasicValidationError from '@rover/react-lib/src/components/formFields/FormValidationError/FormBasicValidationError';
import InlineErrorDateInput, {
  errorMessageType,
} from '@rover/react-lib/src/components/formFields/InlineErrorDateInput/InlineErrorDateInputGeneric';
import LabelAndErrorFormGroup from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import dayFactory from '@rover/react-lib/src/factories/dayFactory';
import useOnClickOutside from '@rover/react-lib/src/hooks/useOnClickOutside';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import getDateTimeFormatMapForLang from '@rover/shared/js/constants/i18n/datetime';
import type { Day } from '@rover/types';
import Enum from '@rover/types/src/Enum';
import { toMessageDescriptor } from '@rover/utilities/i18n';

import '@rover/utilities/allMomentLocales';
import 'react-day-picker/lib/style.css';

import { DatePickerProps } from '../GenericCalendar';
import MultiDatePickerGeneric from '../MultiDatePicker/MultiDatePickerGeneric';

import createRenderDayForHolidays from './createRenderDayForHolidays';
import DatePickerCaption from './DatePickerCaption';
import DatePickerNavbar from './DatePickerNavbar';
import DatePickerStylingWrapper from './DatePickerStylingWrapper';

const DayPickerLazy = React.lazy(() => import('react-day-picker'));

const SELECT_START_DATE = t`Select start date`;
const CLEAR_BUTTON_CTA = t`Clear dates`;

export const StyledIcon = styled(Calendar)`
  position: absolute;
  height: ${(props) => props.iconStyle?.height ?? '16px'};
  width: ${(props) => props.iconStyle?.width ?? '16px'};
  right: ${(props) => props.iconStyle?.right ?? Spacing.M.toString()};
  top: 11px;
  fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  pointer-events: none;
`;

export const StyledCloseIcon = styled(Close)`
  height: 16px;
  width: 16px;
  fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  pointer-events: none;
`;

const StyledDayPicker = styled(DayPickerLazy)`
  ${MQ.XXS.toString()} {
    .DayPicker-Day {
      aspect-ratio: 1 / 1; //Keeps a square shape for the day
      box-sizing: border-box;
      min-width: unset;
      width: calc(100% / 7);
    }
  }
`;

export class CalendarDirection extends Enum<string> {
  static UP = new CalendarDirection('up');

  static DOWN = new CalendarDirection('down');

  static FLEX = new CalendarDirection('flex');

  static isLocked = true;
}

export function parseDatePopoverErrors(
  validationErrors,
  isInExperiment
): undefined | string | MessageDescriptor {
  /* The component receives errors from MultiDateTimePicker, which contain both date and time errors.
  To show the correct message on the calendar, we need to distinguish between them: one error = date validation,
  list of errors = time picker's validation. In the future, popovers will be deprecated and date/time components
  separated - remember to get rid of this function if newCalendarInteraction experiment wins! */
  if (isInExperiment) {
    return undefined;
  }
  return Array.isArray(validationErrors) ? undefined : validationErrors;
}

type DayPickerInputProps = React.ComponentProps<typeof DayPickerInput>;

const DATE_PICKER_CALENDAR_HEIGHT = 320;

export type DatePickerHandle = {
  showDatePicker: () => void;
};

type KeyboardEvent = {
  key: string;
  preventDefault(): void;
};

function checkDateErrors(selectedDate, disableValidation = false): MessageDescriptor | undefined {
  if (disableValidation) return undefined;

  if (!selectedDate) {
    return SELECT_START_DATE;
  }
  return undefined;
}

const DatePicker = forwardRef<DatePickerHandle, DatePickerProps>(function DatePicker(
  {
    allowKeyboardInput,
    asCalendar,
    className,
    date,
    id,
    isDayBlocked,
    language,
    minDate,
    maxDate,
    initialMonth,
    onChange,
    placeholder,
    calendarDirection,
    holidays,
    modifiers,
    validationError: validationErrorProp,
    validationType,
    newCalendarInteraction = false,
    serviceName,
    serviceSubtitle,
    disableValidation = false,
    isMobileBrowser,
    inputStyle,
    onClickFromSearch,
    isDismissible,
    isRequired,
    syncInputWithDate = false,
    ...other
  }: DatePickerProps,
  externalDatepickerRef
) {
  let focusTimeout: ReturnType<typeof setTimeout> | undefined;
  let blurTimeout: ReturnType<typeof setTimeout> | undefined;
  let observer: IntersectionObserver | undefined;
  let potentialValidationError: errorMessageType;
  let isObserving = false;

  const [userIsTouching, setUserIsTouching] = useState(false);
  const [validationError, setValidationError] = useState<errorMessageType>(undefined);
  const [bottomSheetValidationError, setBottomSheetValidationError] =
    useState<errorMessageType>(undefined);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [calendarDropUp, setCalendarDropUp] = useState(false);
  const [calendarHeightOffset, setCalendarHeightOffset] = useState(40);
  const [calendarInset, setCalendarInset] = useState(0);
  const [touchingWhileOpen, setTouchingWhileOpen] = useState(false);
  const [showCalendar, setShowCalendar] = useState<boolean | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(date || null);

  const { i18n, DF } = useI18n();

  const calendarWrapperId = useId();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dayPickerRef = useRef<DayPickerInput>(null);

  useOnClickOutside(wrapperRef, () => {
    setShowCalendar(false);
    if (showCalendar) {
      if (!disableValidation) setValidationError(checkDateErrors(selectedDate));
    }
  });

  const inputPassedRootMargin = (entries: IntersectionObserverEntry[]): void => {
    if (!isObserving) return; // This prevents the function from executing if it's still in the JS event loop queue when unobserve is called

    entries.forEach((entry) => {
      setCalendarDropUp(
        calendarDirection !== CalendarDirection.DOWN && entry.intersectionRatio < 1
      );
    });
  };

  const getObserver = (): IntersectionObserver =>
    new IntersectionObserver(inputPassedRootMargin, {
      root: null,
      rootMargin: `0px 0px -${DATE_PICKER_CALENDAR_HEIGHT}px 0px`,
      threshold: 1.0,
    });

  const observe = (): void => {
    isObserving = true;
    observer = getObserver();
    if (!(wrapperRef.current instanceof HTMLElement)) return;
    observer.observe(wrapperRef.current);
    const inputHeight = wrapperRef.current.getBoundingClientRect().height;
    setCalendarHeightOffset(inputHeight);
  };

  const unobserve = (): void => {
    if (observer && wrapperRef?.current) {
      observer.unobserve(wrapperRef.current);
      isObserving = false;
    }
  };

  useEffect(() => {
    return (): void => {
      unobserve();
      clearTimeout(focusTimeout);
      clearTimeout(blurTimeout);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedDate) {
      setBottomSheetValidationError(undefined);
    }
  }, [serviceName, selectedDate]);

  useEffect(() => {
    if (syncInputWithDate) {
      setSelectedDate(date ?? null);
    }
  }, [date, syncInputWithDate]);

  const dayPickerLateralAdjust = (): void => {
    const windowWidth = window.innerWidth;
    const wrapperEl = wrapperRef?.current?.getElementsByClassName('DayPickerInput-Overlay')[0];
    if (!wrapperEl) return;
    const { width: calendarWidth } = wrapperEl.getBoundingClientRect();
    const { left: inputLeft, right: inputRight } =
      wrapperRef?.current?.getBoundingClientRect() || {};
    const calendarRight = inputLeft + calendarWidth;
    let inset = 0;

    // If the calendar goes off the right side of the screen, try the left instead
    if (calendarRight >= windowWidth) {
      const overshootRight = calendarRight - windowWidth;
      inset = calendarRight - inputRight;
      const overshootLeft = inset - inputLeft;

      // If the calendar overshoots the left more than the right, reset to right
      if (overshootLeft > overshootRight) {
        inset = 0;
      }
    }

    // Make sure the calendar is inside the right side of the screen
    if (calendarRight - inset > windowWidth) {
      inset = calendarRight - windowWidth;
    }

    // Make sure the calendar is inside the left side of the screen
    if (inputLeft - inset < 0) {
      inset = inputLeft;
    }

    setCalendarInset(inset);
  };

  const handleDayChange: DayPickerInputProps['onDayChange'] = (newDate: Date, { disabled }) => {
    if (disabled) return;

    if (newDate) {
      potentialValidationError = undefined;
      setValidationError(undefined);
      onChange(newDate);
      setSelectedDate(newDate);
      !isMobileBrowser && setShowCalendar(false);
    } else {
      potentialValidationError = `Use the format ${
        getDateTimeFormatMapForLang(language).DATE_SHORT
      }`;
    }
  };

  const handleOutsideTouchMove = (): void => setTouchingWhileOpen(true);
  const handleOutsideTouchEnd = useCallback(
    (event: Event): void => {
      // Close if there was no scrolling
      if (!touchingWhileOpen) {
        // `dayPickerRef.getDayPicker().dayPicker` has the DOM element
        const dayPickerComponent = dayPickerRef?.current?.getDayPicker();

        if (dayPickerComponent) {
          const dayPickerEl = dayPickerComponent.dayPicker;
          const dayPickerInputEl = dayPickerRef?.current?.getInput();

          const targetIsOutsideDayPicker =
            !dayPickerEl.contains(event.target as Node) &&
            !dayPickerInputEl.contains(event.target as Node);

          if (targetIsOutsideDayPicker && dayPickerRef?.current) {
            dayPickerRef.current.hideDayPicker();
          }
        }
      }

      setTouchingWhileOpen(false);
    },
    [touchingWhileOpen]
  );

  useEffect(() => {
    if (calendarIsOpen) {
      document.addEventListener('touchend', handleOutsideTouchEnd, false);
    }
    return (): void => {
      document.removeEventListener('touchend', handleOutsideTouchEnd, false);
    };
  }, [calendarIsOpen, handleOutsideTouchEnd]);

  useEffect(() => {
    if (showCalendar && onClickFromSearch) onClickFromSearch();
    if (showCalendar === false && newCalendarInteraction && isMobileBrowser) {
      const error = checkDateErrors(selectedDate);
      if (!disableValidation) setValidationError(error);
    }
  }, [showCalendar, newCalendarInteraction, isMobileBrowser, onClickFromSearch, disableValidation]);

  const handleDayPickerShow = (): void => {
    dayPickerLateralAdjust();
    // Add a slight delay to allow the IntersectionObserver to update before we display the dropdown
    focusTimeout = setTimeout(() => setCalendarIsOpen(true), 100);
    observe();
    document.addEventListener('touchmove', handleOutsideTouchMove, false);
  };

  const handleDayPickerHide = (): void => {
    unobserve();
    setCalendarIsOpen(false);
    document.removeEventListener('touchmove', handleOutsideTouchMove, false);
  };

  const handleInputBlur = (): void => {
    // setTimeout here ensures this.state.calendarIsOpen below accurately reflects the UI
    setTimeout(() => {
      // avoid inadvertently closing the calendar UI by only updating state when the calendar is closed
      if (potentialValidationError && !calendarIsOpen && !disableValidation) {
        setValidationError(potentialValidationError);
      }
    }, 0);
  };

  // last day in the array is the one the user selected
  const handleCalendarChange = (selectedDays: Day[]): void => {
    const addedDay = selectedDays.pop();
    if (addedDay) onChange(addedDay.date ? addedDay.date : undefined);
  };

  const handleTouchStart = (): void => {
    // `userIsTouching` temporarily sets input to readonly to avoid popping keyboard on mobile
    setUserIsTouching(true);
  };

  const showDatePicker = (): void => {
    if (dayPickerRef?.current) {
      dayPickerRef.current.getInput().focus();
      observe();
      dayPickerRef.current.showDayPicker();
      setTimeout(() => dayPickerLateralAdjust());
    }
  };

  useImperativeHandle(externalDatepickerRef, () => ({ showDatePicker }));

  const dayPickerProps = {
    value: date,
    fromMonth: minDate,
    toMonth: maxDate,
    initialMonth,
    month: isMobileBrowser ? minDate : initialMonth || minDate,
    disabledDays: [
      {
        before: minDate,
        after: maxDate,
      },
      isDayBlocked,
    ],
    selectedDays: selectedDate ? [selectedDate] : [],
    navbarElement: DatePickerNavbar,
    captionElement: DatePickerCaption,
    localeUtils: MomentLocaleUtils,
    locale: language,
    modifiers: {
      holiday: holidays,
      ...modifiers,
    },
    renderDay: createRenderDayForHolidays(holidays || []),
  };

  const readOnly = allowKeyboardInput ? userIsTouching : true;

  function getStringFromDates(): string {
    return selectedDate ? moment(selectedDate).format('ddd, DD MMM YYYY') : '';
  }

  const dynamicSubtitle = (): MessageDescriptor | undefined => {
    if (!selectedDate) {
      return t`Select start date`;
    }
    return undefined;
  };

  function clearDateSelection(): void {
    setSelectedDate(null);
    onChange(undefined);
  }

  const numberOfMonthsAllowed = (): number => {
    if (maxDate) {
      // Adding 2 to the result to count both first and last month
      return Math.abs(moment().diff(moment(maxDate), 'months')) + 2;
    }
    // Adding 2 to the result to count both first and last month
    return Math.abs(moment().diff(moment(getMaxRequestDate()), 'months')) + 2;
  };

  const ariaAnnouncement = (): MessageDescriptor => {
    if (date) {
      return t`Selected date ${moment(date).format(DF.DATE_FULL)}`;
    }

    return '' as unknown as MessageDescriptor;
  };

  const renderInput = (isMobile = false, isReadOnly = false): React.ReactNode => {
    return (
      <Box
        width={isMobileBrowser && showCalendar ? '100%' : 'unset'}
        position="relative"
        marginBottom="4x"
      >
        <InlineErrorDateInput
          inputId={id}
          style={inputStyle}
          onKeyDown={(event: KeyboardEvent): void => {
            if (event.key === 'Enter') {
              setShowCalendar(!showCalendar);
            } else if (event.key === ' ') {
              event.preventDefault();
              setShowCalendar(!showCalendar);
            }
          }}
          onClick={(): void => {
            !isMobile && setShowCalendar(!showCalendar);
          }}
          value={getStringFromDates()}
          errorMessage={validationError || validationErrorProp}
          placeholder={
            typeof placeholder === 'string' ? placeholder : i18n._(toMessageDescriptor(placeholder))
          }
          onIconClick={(): void => {
            !isMobile && setShowCalendar(!showCalendar);
          }}
          aria-expanded={showCalendar}
          aria-controls={calendarWrapperId}
          aria-labelledby={other['aria-labelledby']}
          aria-label={other['aria-label']}
          aria-required={isRequired}
          tabIndex={isReadOnly ? -1 : 0}
        />
      </Box>
    );
  };

  const renderMobile = (formGroupProps): JSX.Element => {
    return (
      <BottomSheet
        isOpen={!!showCalendar}
        onRequestClose={() => setShowCalendar(false)}
        isDismissible={isDismissible}
      >
        <DatePickerStylingWrapper
          id={calendarWrapperId}
          className={className}
          readOnly={readOnly}
          styleAsError={!!validationError || !!validationErrorProp}
          calendarDropUp={calendarDirection === CalendarDirection.UP || calendarDropUp}
          ref={isMobileBrowser ? null : wrapperRef}
          calendarHeightOffset={calendarHeightOffset}
          calendarInset={calendarInset}
          calendarIsOpen={calendarIsOpen}
          newCalendarInteraction={newCalendarInteraction}
          isMobileBrowser={newCalendarInteraction && isMobileBrowser}
        >
          <Flex flexDirection="column" alignItems="center">
            <A11yHiddenBox aria-live="assertive" role="alert">
              {i18n._(ariaAnnouncement())}
            </A11yHiddenBox>
            <Heading size="200" mb="1x">
              {i18n._(serviceName as string)}
            </Heading>
            <Paragraph size="100" mb="6x" textAlign="center" textColor="secondary">
              {dynamicSubtitle() && i18n._(dynamicSubtitle() as MessageDescriptor)}
            </Paragraph>
            <ScrollableDayPicker
              wrapperStyles={{ overflow: 'auto', height: '350px', width: '100%' }}
              initialMonth={dayPickerProps.initialMonth || dayPickerProps.value}
            >
              <Suspense fallback={null}>
                <StyledDayPicker
                  {...formGroupProps}
                  {...dayPickerProps}
                  initialMonth={undefined}
                  onDayClick={handleDayChange}
                  ref={dayPickerRef}
                  canChangeMonth={false}
                  numberOfMonths={numberOfMonthsAllowed()}
                />
              </Suspense>
            </ScrollableDayPicker>
            {bottomSheetValidationError && (
              <Flex marginLeft="0x" width="100%">
                <FormBasicValidationError
                  errorMessage={checkDateErrors(selectedDate) as MessageDescriptor}
                  id="input-error"
                />
              </Flex>
            )}
            <Button
              mt="6x"
              size="small"
              variant="primary"
              height={DSTokenMap.SPACE_8X}
              fullWidth
              padding={DSTokenMap.SPACE_0X}
              onClick={(): void => {
                const error = checkDateErrors(selectedDate);
                if (!disableValidation) setValidationError(error);
                setBottomSheetValidationError(error);
                if (!error) {
                  setShowCalendar(false);
                }
              }}
            >
              {i18n._('Save dates')}
            </Button>
            <Button
              mt="3x"
              size="small"
              variant="flat"
              height={DSTokenMap.SPACE_8X}
              fullWidth
              padding={DSTokenMap.SPACE_0X}
              onClick={(): void => {
                if (selectedDate) {
                  setBottomSheetValidationError(undefined);
                }
                clearDateSelection();
                const error = checkDateErrors(undefined);
                if (!disableValidation) {
                  setValidationError(error);
                }
              }}
            >
              {i18n._(CLEAR_BUTTON_CTA)}
            </Button>
          </Flex>
        </DatePickerStylingWrapper>
      </BottomSheet>
    );
  };

  const renderDesktop = (formGroupProps): JSX.Element => {
    return (
      <Box
        id={calendarWrapperId}
        pb="0x"
        mt="5x"
        sx={{ backgroundColor: 'white' }}
        width="640px"
        border={`${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()}`}
        borderRadius={DSTokenMap.BORDER_RADIUS_SECONDARY}
        data-testid="date-picker-desktop"
      >
        <DayPicker
          {...formGroupProps}
          {...dayPickerProps}
          onDayClick={handleDayChange}
          ref={dayPickerRef}
          numberOfMonths={2}
        />
      </Box>
    );
  };

  const dayPickerInputProps = {
    value: date,
    onDayChange: handleDayChange,
    onDayPickerShow: handleDayPickerShow,
    onDayPickerHide: handleDayPickerHide,
    format: getDateTimeFormatMapForLang(language).DATE_SHORT,
    formatDate: MomentLocaleUtils.formatDate,
    parseDate: MomentLocaleUtils.parseDate,
    dayPickerProps,
    inputProps: {
      autoComplete: 'off',
      id,
      onBlur: handleInputBlur,
      onTouchStart: handleTouchStart,
      readOnly,
    },
  };

  if (asCalendar) {
    const selectedDays = date ? [dayFactory({ date })] : [];
    return (
      <MultiDatePickerGeneric
        isMobileBrowser={!!isMobileBrowser}
        {...other}
        {...dayPickerProps}
        selectedDays={selectedDays}
        onChange={handleCalendarChange}
        disableBeforeDateTime={minDate}
        disableAfterDateTime={maxDate || undefined}
        holidays={holidays}
        language={language}
        validationError={validationErrorProp}
        validationType={validationType}
        serviceSubtitle={serviceSubtitle}
        serviceName={serviceName}
        newCalendarInteraction={newCalendarInteraction}
      />
    );
  }

  return (
    <LabelAndErrorFormGroup
      {...other}
      useLabelElementAsCaption={false}
      validationError={parseDatePopoverErrors(
        validationError || validationErrorProp,
        newCalendarInteraction
      )}
      validationType={validationType}
    >
      {({ ...formGroupProps }): JSX.Element => {
        // In some cases, we need the `id` attribute generated from the `LabelAndErrorFormGroup`
        dayPickerInputProps.inputProps.id = dayPickerInputProps.inputProps.id || formGroupProps.id;

        return (
          <DatePickerStylingWrapper
            className={className}
            readOnly={readOnly}
            styleAsError={!!validationError || !!validationErrorProp}
            calendarDropUp={calendarDirection === CalendarDirection.UP || calendarDropUp}
            ref={isMobileBrowser ? null : wrapperRef}
            calendarHeightOffset={calendarHeightOffset}
            calendarInset={calendarInset}
            calendarIsOpen={calendarIsOpen}
            newCalendarInteraction={newCalendarInteraction}
            isMobileBrowser={newCalendarInteraction && isMobileBrowser}
            data-testid="date-picker-generic"
          >
            {newCalendarInteraction && renderInput()}
            {newCalendarInteraction &&
              showCalendar &&
              isMobileBrowser &&
              renderMobile(formGroupProps)}
            {newCalendarInteraction &&
              showCalendar &&
              !isMobileBrowser &&
              renderDesktop(formGroupProps)}
            {!newCalendarInteraction && (
              <DayPickerInput
                {...formGroupProps}
                {...dayPickerInputProps}
                // @ts-expect-error TS is being silly here, i18n._() can be called with string or MessageDescriptor
                placeholder={i18n._(placeholder)}
                inputProps={{
                  ...dayPickerInputProps.inputProps,
                  id,
                  // This label helps screen readers tell users about the date format they must type
                  // It gets added here, so we have access to i18n
                  'aria-label': i18n._(
                    /* i18n: The var here will be a localized date string format, ex: 'date input in MM/DD/YYYY format' */
                    t`date input in ${getDateTimeFormatMapForLang(language).DATE_SHORT} format`
                  ),
                }}
                ref={dayPickerRef}
              />
            )}
            {!newCalendarInteraction && <StyledIcon onClick={showDatePicker} />}
          </DatePickerStylingWrapper>
        );
      }}
    </LabelAndErrorFormGroup>
  );
});

DatePicker.defaultProps = {
  allowKeyboardInput: true,
  asCalendar: false,
  calendarDirection: CalendarDirection.FLEX,
  className: '',
  date: undefined,
  holidays: [],
  id: '',
  isDayBlocked: undefined,
  maxDate: undefined,
  minDate: new Date(),
  placeholder: '',
  serviceSubtitle: '',
  serviceName: '',
  isMobileBrowser: false,
};

export default DatePicker;
