import React, { ChangeEvent, useCallback, useId } from 'react';
import { ChangeHandler, useController } from 'react-hook-form';

import SENSITIVE_DATA_CLASS from '@rover/react-lib/src/constants/sensitiveData.constants';

import { Flex } from '../../../core';
import { FeedbackMessage, PrimaryLabel, SecondaryLabel } from '../sharedTypography';
import { getAriaDescribedByString } from '../utils';

import { RadioFieldCommonProps } from './RadioField.common';
import RadioFieldOption from './RadioFieldOption';

export type Props = RadioFieldCommonProps;

const RadioField = ({
  control,
  disabled,
  fieldError,
  fieldProps,
  helperMessage,
  name,
  onChange = async () => {},
  options,
  primaryLabel,
  primaryLabelA11yHidden,
  primaryLabelSize,
  required = true,
  secondaryLabel,
  controlSide = 'left',
  sensitive = false,
  variant = 'default',
  primaryLabelVariant,
}: Props): JSX.Element => {
  const primaryLabelId = useId();
  const fieldErrorId = useId();
  const helperMessageId = useId();
  const secondaryLabelId = useId();

  // only use useController to get `currentFieldValue` and calculate `isSelected`
  // for each option. "isSelected" is used for a11y enhancements, NOT to create
  // controlled inputs. Uncontrolled inputs are preferred on web for performance reasons
  const {
    field: { value: currentFieldValue },
  } = useController({ control, defaultValue: '', name });

  const handleOnChange = useCallback<(e: ChangeEvent<HTMLInputElement>) => void>(
    async (e) => {
      onChange({ webEvent: e, value: e.target.value });
      fieldProps.onChange(e);
    },
    [onChange, fieldProps]
  );

  // both inline variants should have options in a row that can wrap if needed
  const optionFlexFlow =
    variant === 'inline-options' || variant === 'fully-inline' ? 'row wrap' : 'column';

  return (
    <Flex
      as="fieldset"
      role="radiogroup"
      mb="6x"
      p="0x" // remove default fieldset padding
      border="none"
      aria-describedby={getAriaDescribedByString([
        [fieldError, fieldErrorId],
        [secondaryLabel, secondaryLabelId],
        [helperMessage, helperMessageId],
      ])}
      aria-disabled={disabled}
      aria-invalid={fieldError ? 'true' : 'false'}
      aria-required={required}
      className={sensitive ? SENSITIVE_DATA_CLASS : undefined}
      sx={{
        flexFlow: variant === 'fully-inline' ? 'row wrap' : 'column',
      }}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        pr={variant === 'fully-inline' ? '3x' : undefined}
      >
        <PrimaryLabel
          as="legend"
          required={required}
          primaryLabel={primaryLabel}
          primaryLabelA11yHidden={primaryLabelA11yHidden}
          primaryLabelSize={primaryLabelSize}
          sx={variant === 'fully-inline' && !secondaryLabel ? { mb: '0px !important' } : undefined}
        />
        <SecondaryLabel
          secondaryLabel={secondaryLabel}
          labelId={secondaryLabelId}
          mb={variant === 'fully-inline' ? '0x' : undefined}
        />
      </Flex>
      <Flex
        flexGrow={variant === 'fully-inline' ? '1' : undefined}
        sx={{
          flexFlow: optionFlexFlow,
        }}
        data-testid="kff-radio-input-flex-parent"
      >
        {options.map((option) => (
          <RadioFieldOption
            // the `as` here is fine, there is just a small mismatch between the types
            fieldProps={{ ...fieldProps, onChange: handleOnChange as ChangeHandler }}
            fieldVariant={variant}
            primaryLabelVariant={primaryLabelVariant}
            key={option.value}
            primaryLabel={option.primaryLabel}
            secondaryLabel={option.secondaryLabel}
            value={option.value}
            id={`${primaryLabelId}-${option.value}`}
            disabled={disabled}
            isSelected={option.value === currentFieldValue}
            controlSide={controlSide}
          />
        ))}
      </Flex>
      <FeedbackMessage
        fieldError={fieldError}
        fieldErrorId={fieldErrorId}
        helperMessage={helperMessage}
        helperMessageId={helperMessageId}
      />
    </Flex>
  );
};

export default RadioField;
