/* This component should subtitute MultiDatePicker once DEV-95888 is done */
/* eslint-disable rover/no-platform-specific-globals-or-imports */
import React, { useEffect, useId, useMemo } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import moment from 'moment';

import { Box, Button, Flex, Heading, Paragraph } from '@rover/kibble/core';
import BottomSheet from '@rover/kibble/official-patterns/BottomSheet';
import { A11yHiddenBox, DSTokenMap } from '@rover/kibble/styles';
import { parseDatePopoverErrors } from '@rover/react-lib/src/components/datetime/DatePicker/DatePicker';
import ScrollableDayPicker from '@rover/react-lib/src/components/datetime/ScrollableDayPicker/ScrollableDayPicker';
import FormBasicValidationError from '@rover/react-lib/src/components/formFields/FormValidationError/FormBasicValidationError';
import InlineErrorDateInput from '@rover/react-lib/src/components/formFields/InlineErrorDateInput/InlineErrorDateInputGeneric';
import LabelAndErrorFormGroup from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import dayFactory from '@rover/react-lib/src/factories/dayFactory';
import useOnClickOutside from '@rover/react-lib/src/hooks/useOnClickOutside';
import { useSchedulerForm } from '@rover/react-lib/src/pages/contact-sitter/ContactSitterPage/components/ContactSitterForm/components/ServiceScheduler/contexts/SchedulerFormContext';
import { computeAriaLabelFromDays, getStringFromDates } from '@rover/react-lib/src/utils/datetime';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import getDateTimeFormatMapForLang from '@rover/shared/js/constants/i18n/datetime';
import { Day } from '@rover/types';

import {
  createRenderDayForHolidays,
  DatePickerCaption,
  DatePickerNavbar,
  DatePickerStylingWrapper,
} from '../DatePicker';
import { MultiDatePickerProps, numberOfMonthsAllowed, StyledCloseIcon } from '../GenericCalendar';

type DayPickerClickModifiers = {
  disabled: boolean;
  selected: boolean;
};

type KeyboardEvent = {
  key: string;
  preventDefault(): void;
};

const DATES_INSIDE_ERROR_MESSAGE = t`Select your service dates`;
const DATES_ERROR_MESSAGE = t`Select at least one day`;
const CLEAR_BUTTON_CTA = t`Clear dates`;

function checkDateErrors(
  selectedDays: Day[],
  insideBottomSheet: boolean = false
): undefined | MessageDescriptor | string {
  if (selectedDays.length > 0) {
    return undefined;
  }
  if (insideBottomSheet) {
    return DATES_INSIDE_ERROR_MESSAGE;
  }
  return DATES_ERROR_MESSAGE;
}

function handleDayClick<T extends Day>(
  selectedDays: T[],
  onChange: (days: Array<Day & T>) => void,
  clickedDate: Date,
  // eslint-disable-next-line default-param-last
  { selected, disabled }: DayPickerClickModifiers = { selected: false, disabled: false },
  language: string
): void {
  if (disabled) return;
  if (selected) {
    const selectedDates = selectedDays.map((d) => d.date);
    const selectedIndex = selectedDates.findIndex((d) => d && DateUtils.isSameDay(d, clickedDate));
    selectedDays.splice(selectedIndex, 1);
  } else {
    const DF = getDateTimeFormatMapForLang(language);
    selectedDays.push(
      // @ts-expect-error TS is mad we're assigning a Day to T, when T could have extra props.  This isn't great but the consumers of the component seem to handle it fine
      dayFactory({
        name: moment(clickedDate).format(DF.MONTH_DAY_MED),
        date: clickedDate,
      })
    );
  }
  onChange([...selectedDays]);
}

function clearDaysSelection<T extends Day>(
  selectedDays: T[],
  onChange: (days: Array<Day & T>) => void
): void {
  selectedDays.splice(0, selectedDays.length);
  onChange([...selectedDays]);
}

function MultiDatePicker({
  className = '',
  disableAfterDateTime,
  disableBeforeDateTime = new Date(),
  holidays = [],
  initialMonth,
  language,
  onChange,
  disabledDays = [],
  selectedDays,
  newCalendarInteraction = false,
  serviceName,
  serviceSubtitle,
  maxDate,
  fromMonth,
  validationType,
  isMobileBrowser,
  isDismissible,
  ...other
}: MultiDatePickerProps<Day>): JSX.Element {
  const dayPickerProps = {
    fromMonth: fromMonth || disableBeforeDateTime,
    toMonth: disableAfterDateTime,
    initialMonth,
    disabledDays: [{ before: disableBeforeDateTime, after: disableAfterDateTime }, ...disabledDays],
    selectedDays: selectedDays.map((d) => d.date),
    onDayClick: (clickedDate, modifiers) =>
      handleDayClick<Day>(selectedDays, onChange, clickedDate, modifiers, language),
    navbarElement: DatePickerNavbar,
    captionElement: DatePickerCaption,
    localeUtils: MomentLocaleUtils,
    locale: language,
    modifiers: {
      holiday: holidays,
    },
    renderDay: createRenderDayForHolidays(holidays),
  };

  const [showCalendar, setShowCalendar] = React.useState<boolean | null>(null);
  const calendarWrapperId = useId();
  const calendarWrapperRef = React.useRef<HTMLDivElement>(null);
  const [localError, setLocalError] = React.useState<undefined | MessageDescriptor | string>(
    undefined
  );
  const { isDirty, onFormDirtinessChanged } = useSchedulerForm();
  const DF = useMemo(() => getDateTimeFormatMapForLang(language), [language]);

  const { i18n } = useI18n();

  useOnClickOutside(calendarWrapperRef, () => {
    if (showCalendar) {
      setShowCalendar(false);
      setLocalError(checkDateErrors(selectedDays));
    }
  });

  useEffect(() => {
    if (showCalendar === false) setLocalError(checkDateErrors(selectedDays));
  }, [showCalendar]);

  useEffect(() => {
    if (!isDirty) {
      setLocalError(undefined);
    }
  }, [isDirty]);

  useEffect(() => {
    if (localError) {
      onFormDirtinessChanged?.(true);
    }
  }, [localError, onFormDirtinessChanged]);

  const renderInput = (validationError, isMobile = false): React.ReactNode => {
    const dateInputString = getStringFromDates(selectedDays);
    const ariaLabel = other['aria-label'] || computeAriaLabelFromDays(selectedDays, i18n, DF);

    return (
      <Box
        width={isMobile && showCalendar ? '80vw' : 'unset'}
        position="relative"
        marginBottom="6x"
      >
        <InlineErrorDateInput
          onClick={(): void => {
            if (isMobile && showCalendar) {
              return;
            }
            setShowCalendar(!showCalendar);
          }}
          onKeyDown={(event: KeyboardEvent): void => {
            if (event.key === 'Enter') {
              setShowCalendar(!showCalendar);
            } else if (event.key === ' ') {
              event.preventDefault();
              setShowCalendar(!showCalendar);
            }
          }}
          value={typeof dateInputString === 'string' ? dateInputString : i18n._(dateInputString)}
          errorMessage={(validationError || localError) && checkDateErrors(selectedDays)}
          icon={selectedDays.length >= 1 && <StyledCloseIcon data-testid="close-icon" />}
          onIconClick={(): void => {
            if (selectedDays.length >= 1) {
              clearDaysSelection(selectedDays, onChange);
              setLocalError(checkDateErrors(selectedDays));
            } else {
              setShowCalendar(!showCalendar);
            }
          }}
          aria-expanded={showCalendar}
          aria-controls={calendarWrapperId}
          {...(ariaLabel
            ? { 'aria-label': ariaLabel }
            : { 'aria-labelledby': other['aria-labelledby'] })}
        />
      </Box>
    );
  };

  const renderMobile = (formGroupProps): JSX.Element => {
    return (
      <BottomSheet
        isOpen={!!showCalendar}
        onRequestClose={() => setShowCalendar(false)}
        isDismissible={isDismissible}
      >
        <DatePickerStylingWrapper
          className={className}
          styleAsError={!!(other.validationError || localError)}
          isMobileBrowser={newCalendarInteraction && isMobileBrowser}
          newCalendarInteraction={newCalendarInteraction}
          ref={isMobileBrowser ? null : calendarWrapperRef}
        >
          <Flex flexDirection="column" alignItems="center">
            <A11yHiddenBox aria-live="assertive" role="alert">
              {other['aria-label'] || computeAriaLabelFromDays(selectedDays, i18n, DF)}
            </A11yHiddenBox>
            <Heading size="200" mb="1x">
              {i18n._(serviceName as string)}
            </Heading>
            <Paragraph size="100" mb="6x" textAlign="center" maxWidth="80vw" textColor="secondary">
              {i18n._(serviceSubtitle as string)}
            </Paragraph>
            <ScrollableDayPicker
              wrapperStyles={{ overflow: 'auto', height: '350px', width: '100%' }}
              initialMonth={dayPickerProps.initialMonth}
            >
              <DayPicker
                {...formGroupProps}
                {...dayPickerProps}
                initialMonth={undefined}
                numberOfMonths={numberOfMonthsAllowed(maxDate)}
                canChangeMonth={false}
              />
            </ScrollableDayPicker>
            {localError && (
              <Flex marginLeft="0x" width="100%">
                <FormBasicValidationError
                  errorMessage={checkDateErrors(selectedDays) as MessageDescriptor}
                  id="input-error"
                />
              </Flex>
            )}
            <Button
              mt="6x"
              size="small"
              variant="primary"
              height={DSTokenMap.SPACE_8X}
              padding={DSTokenMap.SPACE_0X}
              data-testid="save-selection-button"
              fullWidth
              onClick={(): void => {
                const error = checkDateErrors(selectedDays);
                setLocalError(error);
                if (!error) {
                  setShowCalendar(false);
                }
              }}
            >
              {i18n._('Save dates')}
            </Button>
            <Button
              mt="3x"
              size="small"
              variant="flat"
              height={DSTokenMap.SPACE_8X}
              fullWidth
              padding={DSTokenMap.SPACE_0X}
              onClick={(): void => clearDaysSelection(selectedDays, onChange)}
              data-testid="clear-selection-button"
            >
              {i18n._(CLEAR_BUTTON_CTA)}
            </Button>
          </Flex>
        </DatePickerStylingWrapper>
      </BottomSheet>
    );
  };

  const renderDesktop = (formGroupProps): JSX.Element => {
    return (
      <Box
        pb="0x"
        mt="5x"
        sx={{ backgroundColor: 'white' }}
        width="640px"
        border={`${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()}`}
        borderRadius={DSTokenMap.BORDER_RADIUS_SECONDARY}
      >
        <DayPicker {...formGroupProps} {...dayPickerProps} numberOfMonths={2} />
        <Flex flexDirection="row" justifyContent="space-between" margin="6x" marginTop="3x">
          <Button
            onClick={() => clearDaysSelection(selectedDays, onChange)}
            type="button"
            width={180}
            paddingY="2x"
            data-testid="clear-selection-button"
            aria-label="Clear selection"
            size="small"
          >
            {i18n._('Reset dates')}
          </Button>
          <Button
            onClick={() => setShowCalendar(false)}
            type="button"
            width={180}
            variant="primary"
            paddingY="2x"
            data-testid="save-selection-button"
            aria-label="Save dates"
            size="small"
          >
            {i18n._('Save dates')}
          </Button>
        </Flex>
      </Box>
    );
  };

  return (
    <LabelAndErrorFormGroup
      {...other}
      validationError={parseDatePopoverErrors(other.validationError, newCalendarInteraction)}
      validationType={validationType}
      useLabelElementAsCaption={false}
    >
      {(formGroupProps): JSX.Element => (
        <DatePickerStylingWrapper
          data-testid="multi-date-picker-generic"
          id={calendarWrapperId}
          className={className}
          styleAsError={!!(other.validationError || localError)}
          isMobileBrowser={newCalendarInteraction && isMobileBrowser}
          newCalendarInteraction={newCalendarInteraction}
          ref={isMobileBrowser ? null : calendarWrapperRef}
        >
          {newCalendarInteraction &&
            renderInput(other.validationError || localError, isMobileBrowser)}
          {newCalendarInteraction &&
            showCalendar &&
            isMobileBrowser &&
            renderMobile(formGroupProps)}
          {newCalendarInteraction &&
            showCalendar &&
            !isMobileBrowser &&
            renderDesktop(formGroupProps)}
          {!newCalendarInteraction && <DayPicker {...formGroupProps} {...dayPickerProps} />}
        </DatePickerStylingWrapper>
      )}
    </LabelAndErrorFormGroup>
  );
}

export default MultiDatePicker;
