import {
  ApiFrontendExperimentsListParams,
  FrontendExperiment,
  PaginatedFrontendExperimentList,
  useApiFrontendExperimentsList,
} from '../../../apiClient/latest';
import { ErrorType } from '../../Network/orvalHTTPInterface';

const CONTROL_GROUP = 'control';
const VARIANT_GROUP = 'variant';

// UTILS //

export const isInExperimentGroup =
  (experiments: FrontendExperiment[]) => (experimentName: string, group: string) => {
    return (
      !!experimentName &&
      !!experimentName.length &&
      !!group &&
      !!group.length &&
      !!experiments.find((e: FrontendExperiment) => e.name === experimentName && e.group === group)
    );
  };

export const isControl = (experiments: FrontendExperiment[]) => (experimentName: string) => {
  return isInExperimentGroup(experiments)(experimentName, CONTROL_GROUP);
};

export const isVariant = (experiments: FrontendExperiment[]) => (experimentName: string) => {
  return isInExperimentGroup(experiments)(experimentName, VARIANT_GROUP);
};

export const isCustomVariantGroup =
  (experiments: FrontendExperiment[]) => (experimentName: string, group: string) => {
    return isInExperimentGroup(experiments)(experimentName, group);
  };

// HOOKS //

export type UseFrontendExperiment = ReturnType<typeof useApiFrontendExperimentsList> & {
  data?: PaginatedFrontendExperimentList;
  error: ErrorType<Error> | null;
  isInExperimentGroup: (experimentName: string, group: string) => boolean;
  isControl: (experimentName: string) => boolean;
  isVariant: (experimentName: string) => boolean;
  isCustomVariantGroup: (experimentName: string, group: string) => boolean;
};

export const useFrontendExperiment = (
  { enabled = true }: Partial<{ enabled: boolean }>,
  params?: ApiFrontendExperimentsListParams
): UseFrontendExperiment => {
  const query = useApiFrontendExperimentsList(params, { query: { enabled } });
  const experiments = query.data?.results ?? [];
  return {
    ...query,
    isInExperimentGroup: isInExperimentGroup(experiments),
    isControl: isControl(experiments),
    isVariant: isVariant(experiments),
    isCustomVariantGroup: isCustomVariantGroup(experiments),
  };
};
