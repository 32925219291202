import React from 'react';
import { t } from '@lingui/macro';

import { Box, Image } from '@rover/kibble/core';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import { AVATAR_SIZE, DEFAULT_IMAGE } from './Avatar.constants';
import { Props } from './Avatar.types';

function getSource(src: Props['imageUrl'], type: Props['type']): string {
  if (!!src && !src?.endsWith('.svg')) return src;

  return !type ? DEFAULT_IMAGE.other : DEFAULT_IMAGE?.[type];
}

const Avatar = ({
  imageUrl,
  alt,
  type = 'person',
  size = 'default',
  sx,
  ...rest
}: Props): JSX.Element => {
  const { i18n } = useI18n();

  return (
    <Box
      height={AVATAR_SIZE[size]}
      width={AVATAR_SIZE[size]}
      borderRadius="round"
      sx={{ overflow: 'hidden', ...sx }}
      {...rest}
    >
      <Image
        src={getSource(imageUrl, type)}
        description={alt || i18n._(t`Profile image`)}
        width="100%"
        height="100%"
      />
    </Box>
  );
};

export default Avatar;
