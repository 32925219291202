import React, { useId } from 'react';
import { MessageDescriptor } from '@lingui/core';

import { Calendar } from '@rover/icons';
import { Box } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import TextField from '@rover/react-lib/src/components/formFields/TextField';
import { isMobileBrowser } from '@rover/rsdk/src/modules/Network/userAgent';

export type InlineErrorDateInputProps = {
  onKeyDown?: (any) => void;
  value: string;
  inputId?: string;
  errorMessage: errorMessageType;
  placeholder?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  onIconClick?: () => void;
  disabled?: boolean;
  ariaHidden?: boolean;
  tabIndex?: number;
};

// errorMessageType needs to accept all translation formats, i.e. t`, <Trans> and i18n strings
export type errorMessageType = MessageDescriptor | JSX.Element | string | null | undefined;

const InlineErrorDateInput = ({
  errorMessage,
  onKeyDown,
  value,
  inputId,
  placeholder = '',
  onClick,
  style,
  icon,
  onIconClick,
  disabled = false,
  ariaHidden = false,
  ...other
}: InlineErrorDateInputProps): JSX.Element => {
  const generatedInputId = useId();
  const textFieldStyle: React.CSSProperties = {
    fontSize: isMobileBrowser()
      ? DSTokenMap.TEXT_100_FONT_SIZE.toString()
      : DSTokenMap.TEXT_200_FONT_SIZE.toString(),
    cursor: 'pointer',
    minHeight: DSTokenMap.SPACE_12X,
    // input type button overrides
    textAlign: 'left',
    backgroundColor: DSTokenMap.BACKGROUND_COLOR_PRIMARY.toString(),
    lineHeight: 0,
    ...style,
  };

  return (
    <Box position="relative">
      <TextField
        value={value}
        placeholder={placeholder}
        errorMessage={errorMessage as string}
        disabled={disabled}
        id={inputId ?? generatedInputId}
        inputProps={{
          onKeyDown,
          style: textFieldStyle,
          'aria-controls': other['aria-controls'],
          'aria-expanded': other['aria-expanded'],
          ...(other['aria-label']
            ? { 'aria-label': other['aria-label'], 'aria-labelledby': undefined }
            : { 'aria-labelledby': `${other['aria-labelledby'] ?? ''} ${generatedInputId}` }),
          'aria-hidden': ariaHidden,
          // Design has this input as a text field, but it's a button for the purposes of accessibility
          type: 'button',
          onClick,
          ...other,
        }}
        rightItem={
          value ? (
            icon // StyledCloseIcon
          ) : (
            <Calendar
              width="24px"
              height="24px"
              fill={DSTokenMap.TEXT_COLOR_TERTIARY.toString()}
              data-testid="default-icon"
            />
          )
        }
        rightItemClick={onIconClick}
      />
    </Box>
  );
};

export default InlineErrorDateInput;
