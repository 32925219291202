import React, { FC, useCallback, useRef } from 'react';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import moment from 'moment';
import { styled } from 'styled-components';

import { Box, Button, Flex, Heading, Text } from '@rover/kibble/core';
import BottomSheet from '@rover/kibble/official-patterns/BottomSheet';
import { DSTokenMap } from '@rover/kibble/styles';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

import 'react-day-picker/lib/style.css';

import TextField from '../../formFields/TextField';
import { BirthdayDatePickerProps, StyledIcon as CalendarIcon } from '../GenericCalendar';

import { DatePickerStyleWrapper } from './DatePickerStyleWrapper';
import { MonthYearNavbar } from './MonthYearNavbar';

const getInitialDate = (maxDate?: Date): { month: number; year: number } => {
  const today = moment();

  if (!maxDate || today.isBefore(moment(maxDate))) {
    return { month: today.month(), year: today.year() };
  }

  return { month: moment(maxDate).month(), year: moment(maxDate).year() };
};

const StyledDatePickerStyleWrapper = styled(DatePickerStyleWrapper)`
  .DayPicker {
    min-width: 350px;
  }
  .DayPicker-NavBar {
    display: none;
  }
`;

const BirthdayDatePickerMobileGeneric: FC<BirthdayDatePickerProps> = ({
  placeholder,
  minDate,
  maxDate,
  name,
  value,
  onBlur,
  onChange,
  validationError,
  label,
  subLabel,
}) => {
  const { i18n, DF, locale } = useI18n();
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const [showEmptyError, setShowEmptyError] = React.useState(false);

  const [month, setMonth] = React.useState(
    () => value?.getMonth?.() ?? getInitialDate(maxDate).month
  );
  const [year, setYear] = React.useState(
    () => value?.getFullYear?.() ?? getInitialDate(maxDate).year
  );

  const weekdays = React.useMemo(
    () =>
      moment
        .localeData(locale)
        .weekdays()
        .map((weekday) => weekday[0].toUpperCase()),
    []
  );

  const handleMonth = useCallback(
    (newMonth) => {
      setMonth(newMonth);
      onChange(moment(value).month(newMonth).toDate());
    },
    [value]
  );

  const handleYear = useCallback(
    (newYear) => {
      setYear(newYear);
      onChange(moment(value).year(newYear).toDate());
    },
    [value]
  );

  const handleClick = useCallback(() => {
    if (!value) {
      setShowEmptyError(true);
    } else {
      setShowEmptyError(false);
      setIsOpen(false);
    }
  }, [value]);

  return (
    <>
      <TextField
        rightItem={!value && <CalendarIcon />}
        rightItemSpacing="0px"
        ref={ref}
        readOnly
        onClick={() => {
          setIsOpen(true);
          ref.current?.blur();
        }}
        dataTestId="birthday-date-picker-mobile"
        primaryLabel={label}
        helperMessage={subLabel}
        errorMessage={
          typeof validationError === 'string'
            ? validationError
            : i18n._(validationError as MessageDescriptor)
        }
        value={value && MomentLocaleUtils.formatDate(value)}
        placeholder={placeholder || DF.DATE_SHORT}
        onChange={(d) => d && onChange(MomentLocaleUtils.parseDate(d))}
        inputProps={{
          name,
          onBlur,
          'aria-label': i18n._(
            /* i18n: The var here will be a localized date string format, ex: 'date input in MM/DD/YYYY format' */
            t`date input in ${DF.DATE_SHORT} format`
          ),
          style: {
            minHeight: '40px',
            padding: DSTokenMap.SPACE_2X,
            fontSize: DSTokenMap.TEXT_100_FONT_SIZE,
          },
        }}
      />
      <BottomSheet
        isOpen={isOpen}
        onRequestClose={() => {
          ref.current?.blur();
          setIsOpen(false);
        }}
        style={{ content: { overflow: 'hidden' } }}
        HeaderComponent={
          <>
            <Heading textAlign="center">{i18n._('Birthday')}</Heading>
            <MonthYearNavbar
              minDate={minDate}
              maxDate={maxDate}
              selectedMonth={month}
              selectedYear={year}
              setMonth={handleMonth}
              setYear={handleYear}
            />
          </>
        }
        FooterComponent={
          <Box pt="1x">
            {showEmptyError && (
              <Box mb="6x">
                <Text textColor="error" size="100" data-testid="select-date-error-generic-mobile">
                  {i18n._('Select date of birth')}
                </Text>
              </Box>
            )}
            <Button
              data-testid="button-save-dates-generic-mobile"
              variant="primary"
              size="small"
              fullWidth
              height={DSTokenMap.SPACE_8X}
              padding={DSTokenMap.SPACE_0X}
              onClick={handleClick}
            >
              {i18n._('Save dates')}
            </Button>
          </Box>
        }
      >
        <Flex sx={{ overflow: 'auto' }} flexDirection="column">
          <StyledDatePickerStyleWrapper hasError={!!validationError}>
            <DayPicker
              fromMonth={minDate}
              toMonth={maxDate}
              selectedDays={value}
              month={new Date(year, month)}
              disabledDays={[
                {
                  after: maxDate,
                  before: minDate,
                },
              ]}
              weekdaysShort={weekdays}
              locale={locale}
              localeUtils={MomentLocaleUtils}
              // This code block handles the logic for when a user clicks on a day in the calendar
              onDayClick={(date) => {
                // Set the month and year to the selected date's month and year
                setMonth(date.getMonth());
                setYear(date.getFullYear());
                // If the selected date is between the minDate and maxDate (inclusive), call the onChange function with the selected date
                if (minDate && maxDate && moment(date).isBetween(minDate, maxDate, 'day', '[]')) {
                  setShowEmptyError(false);
                  onChange(date);
                }
                // If the selected date is after the minDate, call the onChange function with the selected date
                else if (moment(date).isAfter(minDate) && !maxDate) {
                  setShowEmptyError(false);
                  onChange(date);
                }
              }}
            />
          </StyledDatePickerStyleWrapper>
        </Flex>
      </BottomSheet>
    </>
  );
};

export default BirthdayDatePickerMobileGeneric;
