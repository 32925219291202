import React, { useCallback, useId } from 'react';

import { Flex } from '../../../core';
import { KibbleFormFieldComponentCommonProps } from '../../types';
import { PrimaryLabel, PrimaryLabelVariants, SecondaryLabel } from '../sharedTypography';
import { preventDefault } from '../utils';

import { RadioFieldOptionConfig, RadioFieldVariants } from './RadioField.common';
import RadioFieldOptionDot from './RadioFieldOptionDot';

export type Props = RadioFieldOptionConfig & {
  fieldProps: KibbleFormFieldComponentCommonProps['fieldProps'];
  fieldVariant: RadioFieldVariants;
  id: string;
  disabled?: boolean;
  required?: boolean;
  isSelected: boolean;
  controlSide?: string;
  primaryLabelVariant?: PrimaryLabelVariants;
};

const RadioFieldOption = ({
  fieldProps,
  fieldVariant,
  primaryLabelVariant = 'option-label',
  id,
  primaryLabel,
  secondaryLabel,
  value,
  disabled,
  required,
  isSelected,
  controlSide = 'left',
}: Props): JSX.Element => {
  const secondaryLabelId = useId();

  const handleWrapperClick = useCallback((e: React.MouseEvent) => {
    // pass click to the input to update the form state
    // In React its probably more correct to do this with a ref,
    // but fieldProps already has a ref and we don't want to mess with it
    e.currentTarget.querySelector('input')?.click();
  }, []);

  const cursor = disabled ? 'not-allowed' : 'pointer';

  return (
    <Flex
      onClick={handleWrapperClick}
      flex={fieldVariant === 'fully-inline' || fieldVariant === 'inline-options' ? '1 0 auto' : '1'}
      flexDirection={controlSide === 'right' ? 'row-reverse' : 'row'}
      alignItems="flex-start"
      justifyContent={controlSide === 'right' ? 'space-between' : undefined}
      border="2px solid"
      borderRadius="secondary"
      borderColor="transparent"
      position="relative"
      sx={{
        cursor,
        // nonstandard padding because we use 2px of the specced 12px for the focus border
        py: '10px',

        '@media (hover: hover)': {
          '&:hover': {
            background: 'interactive.primary_pressed',

            // style the fake radio button when the wrapper is hovered
            // the ring
            'input + div': {
              borderColor: disabled ? 'border.disabled' : 'interactive_border.primary_pressed',
              background: disabled ? 'disabled' : 'interactive.primary_pressed',
              // the dot
              '> div': {
                background: 'interactive.primary_pressed',
              },
            },
          },
        },

        // add a focus outline to the wrapper when the child input has focus
        // the :has() selector doesn't work some old Android browser versions,
        // but we're using it anyway because it's not a huge problem if it doesn't work here
        // see https://roverdotcom.slack.com/archives/CCDP8BVPT/p1711476766625389 for more context
        '&:has(input:focus)': {
          borderColor: 'border.input_focus',
        },
      }}
    >
      <RadioFieldOptionDot
        describedBy={secondaryLabel ? secondaryLabelId : undefined}
        disabled={disabled}
        fieldProps={fieldProps}
        id={id}
        required={required}
        value={value}
        isSelected={isSelected}
      />
      <Flex
        flexDirection="column"
        pl={controlSide === 'right' ? '1x' : '2x'}
        pr={controlSide === 'right' ? '2x' : '1x'}
      >
        <PrimaryLabel
          variant={primaryLabelVariant}
          primaryLabel={primaryLabel}
          webInputId={id}
          // always false here, because the RadioField component handles this
          required={false}
          // && is needed to get higher specificity than the default mb
          sx={{ cursor, '&&': { mb: secondaryLabel ? '1x' : '0x' } }}
          // suppress default label click behavior, let handleWrapperClick deal with it
          // avoids some edge cases with showing the focus outline on the wrapper
          onClick={preventDefault}
        />
        <SecondaryLabel secondaryLabel={secondaryLabel} labelId={secondaryLabelId} mb="0x" />
      </Flex>
    </Flex>
  );
};

export default RadioFieldOption;
