/* This component should substitute MultiDateAutoFillPicker once DEV-95888 is done */
/* eslint-disable rover/no-platform-specific-globals-or-imports */
import React, { Suspense, useCallback, useEffect, useId, useMemo } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { MessageDescriptor } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import moment from 'moment';

import { Box, Button, Flex, Heading, Paragraph } from '@rover/kibble/core';
import BottomSheet from '@rover/kibble/official-patterns/BottomSheet';
import { A11yHiddenBox, DSTokenMap } from '@rover/kibble/styles';
import ScrollableDayPicker from '@rover/react-lib/src/components/datetime/ScrollableDayPicker/ScrollableDayPicker';
import FormBasicValidationError from '@rover/react-lib/src/components/formFields/FormValidationError/FormBasicValidationError';
import InlineErrorDateInput from '@rover/react-lib/src/components/formFields/InlineErrorDateInput/InlineErrorDateInputGeneric';
import LabelAndErrorFormGroup from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import dayFactory from '@rover/react-lib/src/factories/dayFactory';
import useOnClickOutside from '@rover/react-lib/src/hooks/useOnClickOutside';
import { useSchedulerForm } from '@rover/react-lib/src/pages/contact-sitter/ContactSitterPage/components/ContactSitterForm/components/ServiceScheduler/contexts/SchedulerFormContext';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import getDateTimeFormatMapForLang from '@rover/shared/js/constants/i18n/datetime';
import type { Day } from '@rover/types';

import {
  createRenderDayForHolidays,
  DatePickerCaption,
  DatePickerNavbar,
  DatePickerStylingWrapper,
} from '../DatePicker';
import {
  MultiDateAutoFillPickerProps,
  numberOfMonthsAllowed,
  StyledCloseIcon,
} from '../GenericCalendar';

const DayPickerLazy = React.lazy(() => import('react-day-picker'));

const PARTIAL_DATE_FORMAT = 'ddd, DD MMM';
const COMPLETE_DATE_FORMAT = 'ddd, DD MMM YYYY';

const SELECT_ALL_DATES = t`Select start and end dates`;
const SELECT_START_DATE = t`Select start date`;
const SELECT_END_DATE = t`Select end date`;

const CLEAR_BUTTON_CTA = t`Clear dates`;

type DayPickerClickModifiers = {
  disabled: boolean;
  selected: boolean;
};

type KeyboardEvent = {
  key: string;
  preventDefault(): void;
};

export function getMiddleDays<T extends Day>(
  firstDay: T,
  lastDay: T,
  DF: Record<string, string>
): T[] {
  const difference =
    Math.abs(
      moment(firstDay.date).startOf('day').diff(moment(lastDay.date).startOf('day'), 'days')
    ) - 1;
  const allDays: T[] = [firstDay];
  let currentDay = moment(firstDay.date).add(1, 'days');

  while (allDays.length < difference + 1) {
    allDays.push(
      // @ts-expect-error TS is mad we're assigning a Day to T, when T could have extra props.  This isn't great but the consumers of the component seem to handle it fine
      dayFactory({
        name: moment(currentDay).format(DF.MONTH_DAY_MED),
        date: currentDay.toDate(),
      })
    );
    currentDay = moment(currentDay).add(1, 'days');
  }
  allDays.push(
    // @ts-expect-error TS is mad we're assigning a Day to T, when T could have extra props.  This isn't great but the consumers of the component seem to handle it fine
    dayFactory({
      name: moment(lastDay.date).format(DF.MONTH_DAY_MED),
      date: lastDay.date,
    })
  );
  return allDays;
}

function getFirstDay<T extends Day>(selectedDays: T[], isMobile = false): string {
  const firstDay = selectedDays[0].date;
  if (selectedDays.length > 1) {
    const lastDay = selectedDays[selectedDays.length - 1].date;
    // If the first and last day are in the same year, or it's displayed on the bottom sheet, we don't show the year.
    if (firstDay?.getFullYear() === lastDay?.getFullYear() || isMobile) {
      return moment(firstDay).format(PARTIAL_DATE_FORMAT);
    }
    return moment(firstDay).format(COMPLETE_DATE_FORMAT);
  }
  return moment(firstDay).format(PARTIAL_DATE_FORMAT);
}

export function getStringFromDates<T extends Day>(selectedDays: T[]): string {
  if (selectedDays.length === 0) {
    return '';
  }
  const firstDay = getFirstDay(selectedDays);
  if (selectedDays.length === 1) {
    return `${firstDay} -`;
  }
  const lastDay = moment(selectedDays[selectedDays.length - 1].date);
  return `${firstDay} - ${lastDay.format(COMPLETE_DATE_FORMAT)}`;
}

export function clearDaysSelection<T extends Day>(
  selectedDays: T[],
  onChange: (days: Array<Day & T>) => void,
  all = false
): void {
  selectedDays.splice(all ? 0 : 1, selectedDays.length);
  onChange([...selectedDays]);
}

function handleDayClick<T extends Day>(
  selectedDays: T[],
  onChange: (days: Array<Day & T>) => void,
  clickedDate: Date,
  // eslint-disable-next-line default-param-last
  { selected, disabled }: DayPickerClickModifiers = { selected: false, disabled: false },
  language: string
): void {
  if (disabled) return;
  if (selected) {
    if (selectedDays.length === 1) {
      const DF = getDateTimeFormatMapForLang(language);
      selectedDays.push(
        // @ts-expect-error TS is mad we're assigning a Day to T, when T could have extra props.  This isn't great but the consumers of the component seem to handle it fine
        dayFactory({
          name: moment(clickedDate).format(DF.MONTH_DAY_MED),
          date: clickedDate,
        })
      );
      getStringFromDates(selectedDays);
      onChange([...selectedDays]);
      return;
    }
    if (selectedDays.length === 2) {
      const areSameDay = DateUtils.isSameDay(
        selectedDays[0].date as Date,
        selectedDays[1].date as Date
      );
      if (areSameDay) {
        clearDaysSelection(selectedDays, onChange, true);
      }
    }
    const selectedDates = selectedDays.map((d) => d.date);
    const selectedIndex = selectedDates.findIndex((d) => d && DateUtils.isSameDay(d, clickedDate));
    if (selectedDates.length - 1 === selectedIndex) {
      selectedDays.splice(selectedIndex, selectedDates.length - selectedIndex);
    } else {
      selectedDays.splice(selectedIndex + 1, selectedDates.length - selectedIndex);
    }
  } else {
    const DF = getDateTimeFormatMapForLang(language);
    if (selectedDays.length >= 1) {
      if (moment(clickedDate).isBefore(moment(selectedDays[0].date))) {
        while (selectedDays.length > 0) {
          selectedDays.pop();
        }
        selectedDays.push(
          // @ts-expect-error TS is mad we're assigning a Day to T, when T could have extra props.  This isn't great but the consumers of the component seem to handle it fine
          dayFactory({
            name: moment(clickedDate).format(DF.MONTH_DAY_MED),
            date: clickedDate,
          })
        );
        onChange([...selectedDays]);
        return;
      }
      selectedDays.push(
        // @ts-expect-error TS is mad we're assigning a Day to T, when T could have extra props.  This isn't great but the consumers of the component seem to handle it fine
        dayFactory({
          name: moment(clickedDate).format(DF.MONTH_DAY_MED),
          date: clickedDate,
        })
      );
      selectedDays.sort((a, b) => moment(a.date).diff(moment(b.date)));
      const firstDay = selectedDays[0];
      const lastDay = selectedDays[selectedDays.length - 1];
      while (selectedDays.length > 0) {
        selectedDays.pop();
      }
      const newDays = getMiddleDays(firstDay, lastDay, DF);
      newDays.forEach((day) => {
        selectedDays.push(day);
      });
    } else {
      selectedDays.push(
        // @ts-expect-error TS is mad we're assigning a Day to T, when T could have extra props.  This isn't great but the consumers of the component seem to handle it fine
        dayFactory({
          name: moment(clickedDate).format(DF.MONTH_DAY_MED),
          date: clickedDate,
        })
      );
    }
  }
  getStringFromDates(selectedDays);
  onChange([...selectedDays]);
}

function checkStartDateError(selectedDays): undefined | MessageDescriptor {
  if (selectedDays.length > 0) {
    return undefined;
  }
  return SELECT_START_DATE;
}

function checkEndDateError(selectedDays): undefined | MessageDescriptor {
  if (selectedDays.length <= 1) {
    return SELECT_END_DATE;
  }
  return undefined;
}

function checkDateErrors(selectedDays, disableValidation = false): undefined | MessageDescriptor {
  if (disableValidation) return undefined;

  if (!selectedDays.length) {
    return SELECT_ALL_DATES;
  }
  if (selectedDays.length === 1) {
    return SELECT_END_DATE;
  }
  return undefined;
}

function MultiDateAutoFillPicker({
  className = '',
  disableAfterDateTime,
  disableBeforeDateTime = new Date(),
  holidays = [],
  initialMonth,
  language,
  onChange,
  disabledDays = [],
  selectedDays,
  isMobileBrowser,
  serviceName,
  maxDate,
  newCalendarInteraction = false,
  showTwoInputs = false,
  endDatePlaceholder,
  startDatePlaceholder,
  disableValidation = false,
  validationType,
  inputStyle,
  onClickFromSearch,
  isDismissible,
  ...other
}: MultiDateAutoFillPickerProps<Day>): JSX.Element {
  const dayPickerProps = {
    fromMonth: disableBeforeDateTime,
    toMonth: disableAfterDateTime,
    initialMonth,
    disabledDays: [{ before: disableBeforeDateTime, after: disableAfterDateTime }, ...disabledDays],
    selectedDays: selectedDays.map((d) => d.date),
    maxDate,
    onDayClick: (clickedDate, modifiers) =>
      handleDayClick<Day>(selectedDays, onChange, clickedDate, modifiers, language),
    navbarElement: DatePickerNavbar,
    captionElement: DatePickerCaption,
    localeUtils: MomentLocaleUtils,
    locale: language,
    modifiers: {
      holiday: holidays,
    },
    renderDay: createRenderDayForHolidays(holidays),
  };
  const calendarWrapperId = useId();
  const calendarWrapperRef = React.useRef<HTMLDivElement>(null);
  const [showCalendar, setShowCalendar] = React.useState<boolean | null>(null);
  const [localError, setLocalError] = React.useState<undefined | MessageDescriptor>(undefined);
  const [localBottomSheetError, setLocalBottonSheetError] = React.useState<
    undefined | MessageDescriptor
  >(undefined);
  const DF = useMemo(() => getDateTimeFormatMapForLang(language), [language]);
  const { isDirty, onFormDirtinessChanged } = useSchedulerForm();
  const { i18n } = useI18n();

  useEffect(() => {
    if (selectedDays.length === 0) {
      setLocalError(undefined);
      setLocalBottonSheetError(undefined);
    }
  }, [serviceName]);

  useEffect(() => {
    const rangeDays = moment(selectedDays[0]?.date).diff(
      selectedDays[selectedDays.length - 1]?.date,
      'days'
    );
    if (rangeDays && rangeDays + 1 !== selectedDays.length) {
      const newDays = getMiddleDays(selectedDays[0], selectedDays[selectedDays.length - 1], DF);

      clearDaysSelection(selectedDays, onChange, true);
      newDays.forEach((day) => {
        selectedDays.push(day);
      });
      getStringFromDates(selectedDays);
      onChange([...selectedDays]);
    }
  }, []);

  useEffect(() => {
    if (showCalendar && onClickFromSearch) onClickFromSearch();
    if (showCalendar === false) setLocalError(checkDateErrors(selectedDays, disableValidation));
  }, [showCalendar, disableValidation, onClickFromSearch]);

  useOnClickOutside(calendarWrapperRef, () => {
    if (showCalendar) {
      setShowCalendar(false);
      setLocalError(checkDateErrors(selectedDays, disableValidation));
    }
  });

  useEffect(() => {
    if (!isDirty) {
      setLocalError(undefined);
    }
  }, [isDirty]);

  useEffect(() => {
    if (localError) {
      onFormDirtinessChanged?.(true);
    }
  }, [localError, onFormDirtinessChanged]);

  const handleCalendarToggle = useCallback(
    () => setShowCalendar((prevShowCalendar) => !prevShowCalendar),
    []
  );

  const handleClearDates = useCallback(() => {
    if (selectedDays.length) {
      setLocalBottonSheetError(undefined);
    }
    clearDaysSelection(selectedDays, onChange, true);
  }, [onChange, selectedDays]);

  const renderInput = (validationError): JSX.Element => {
    return (
      <InlineErrorDateInput
        aria-expanded={showCalendar}
        aria-controls={showCalendar && calendarWrapperId}
        aria-labelledby={other['aria-labelledby']}
        aria-label={other['aria-label']}
        onKeyDown={(event: KeyboardEvent): void => {
          if (event.key === 'Enter') {
            setShowCalendar(!showCalendar);
          } else if (event.key === ' ') {
            event.preventDefault();
            setShowCalendar(!showCalendar);
          }
        }}
        value={getStringFromDates(selectedDays)}
        errorMessage={
          validationError ? checkDateErrors(selectedDays, disableValidation) : undefined
        }
        onClick={(): void => {
          setShowCalendar(!showCalendar);
        }}
        onIconClick={(): void => {
          // if it shows X button, we clear selection, otherwise toggle the calendar
          if (selectedDays.length >= 1) {
            clearDaysSelection(selectedDays, onChange, true);
            setLocalError(checkDateErrors(selectedDays, disableValidation));
          } else {
            setShowCalendar(!showCalendar);
          }
        }}
        icon={selectedDays.length >= 1 && <StyledCloseIcon />}
      />
    );
  };

  const renderTwoInputs = (validationError?: string | MessageDescriptor | null): JSX.Element => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box flexDirection="column" alignItems="flex-start" flex="1" position="relative">
          <label htmlFor="startDateInput" hidden>
            <Trans>Start date</Trans>
          </label>
          <InlineErrorDateInput
            style={inputStyle}
            inputId="startDateInput"
            value={selectedDays.length > 0 ? getFirstDay(selectedDays, true) : ''}
            errorMessage={
              validationError || localError ? checkStartDateError(selectedDays) : undefined
            }
            placeholder={startDatePlaceholder}
            onClick={handleCalendarToggle}
            onIconClick={handleCalendarToggle}
            aria-controls={showCalendar ? calendarWrapperId : undefined}
            aria-expanded={showCalendar}
          />
        </Box>
        <Box
          flexDirection="column"
          alignItems="flex-start"
          marginLeft="4x"
          position="relative"
          flex="1"
        >
          <label htmlFor="endDateInput" hidden>
            <Trans>End date</Trans>
          </label>
          <InlineErrorDateInput
            style={inputStyle}
            inputId="endDateInput"
            value={
              selectedDays.length > 1
                ? moment(selectedDays[selectedDays.length - 1].date).format(PARTIAL_DATE_FORMAT)
                : ''
            }
            errorMessage={
              validationError || localError ? checkEndDateError(selectedDays) : undefined
            }
            placeholder={endDatePlaceholder}
            onClick={handleCalendarToggle}
            onIconClick={handleCalendarToggle}
            aria-controls={showCalendar ? calendarWrapperId : undefined}
            aria-expanded={showCalendar}
          />
        </Box>
      </Box>
    );
  };

  const ariaAnnouncement = (): MessageDescriptor => {
    if (selectedDays.length === 0) {
      return t`Select start and end date`;
    }
    if (selectedDays.length === 1) {
      return t`Start date ${moment(selectedDays[0].date).format(DF.DATE_FULL)}. Select end date.`;
    }
    return t`Selected dates from ${moment(selectedDays[0].date).format(DF.DATE_FULL)} to ${moment(
      selectedDays[selectedDays.length - 1].date
    ).format(DF.DATE_FULL)}. Tap "Save Dates" to close calendar.`;
  };

  const dynamicSubtitle = (): MessageDescriptor | undefined => {
    if (selectedDays.length === 0) {
      return t`Select start and end dates`;
    }
    if (selectedDays.length === 1) {
      return t`Select end date`;
    }
    return undefined;
  };

  const renderMobile = (formGroupProps): JSX.Element => {
    return (
      <BottomSheet
        isOpen={!!showCalendar}
        onRequestClose={() => setShowCalendar(false)}
        isDismissible={isDismissible}
      >
        <DatePickerStylingWrapper
          id={calendarWrapperId}
          className={className}
          styleAsError={!!(other.validationError || localError)}
          newCalendarInteraction={newCalendarInteraction}
          isMobileBrowser={isMobileBrowser}
          ref={isMobileBrowser ? null : calendarWrapperRef}
        >
          <Flex flexDirection="column" alignItems="center">
            <A11yHiddenBox aria-live="assertive" role="alert">
              {i18n._(ariaAnnouncement())}
            </A11yHiddenBox>
            <Heading size="200" mb="1x">
              {i18n._(serviceName as string)}
            </Heading>
            <Paragraph size="100" mb="6x" textAlign="center" maxWidth="80vw" textColor="secondary">
              {dynamicSubtitle() && i18n._(dynamicSubtitle() as MessageDescriptor)}
            </Paragraph>
            <ScrollableDayPicker
              wrapperStyles={{ overflow: 'auto', height: '350px', width: '100%' }}
              initialMonth={dayPickerProps.initialMonth}
            >
              <Suspense fallback={null}>
                <DayPickerLazy
                  {...formGroupProps}
                  {...dayPickerProps}
                  initialMonth={undefined}
                  numberOfMonths={numberOfMonthsAllowed(maxDate)}
                  canChangeMonth={false}
                />
              </Suspense>
            </ScrollableDayPicker>
            {localBottomSheetError && (
              <Flex marginLeft="0x" width="100%">
                <FormBasicValidationError
                  errorMessage={checkDateErrors(selectedDays) as MessageDescriptor}
                  id="input-error"
                />
              </Flex>
            )}

            <Button
              mt="6x"
              size="small"
              variant="primary"
              fullWidth
              height={DSTokenMap.SPACE_8X}
              padding={DSTokenMap.SPACE_0X}
              onClick={(): void => {
                const error = checkDateErrors(selectedDays);
                if (!disableValidation) setLocalError(error);
                setLocalBottonSheetError(error);
                if (!error) {
                  setShowCalendar(false);
                }
              }}
            >
              {i18n._('Save dates')}
            </Button>
            <Button
              mt="3x"
              size="small"
              variant="flat"
              height={DSTokenMap.SPACE_8X}
              fullWidth
              padding={DSTokenMap.SPACE_0X}
              onClick={handleClearDates}
            >
              {i18n._(CLEAR_BUTTON_CTA)}
            </Button>
          </Flex>
        </DatePickerStylingWrapper>
      </BottomSheet>
    );
  };

  const renderDesktop = (formGroupProps): JSX.Element => {
    return (
      <Box
        id={calendarWrapperId}
        pb="0x"
        mt="5x"
        sx={{ backgroundColor: 'white' }}
        width="640px"
        border={`${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()}`}
        borderRadius={DSTokenMap.BORDER_RADIUS_SECONDARY}
      >
        <DayPicker {...formGroupProps} {...dayPickerProps} numberOfMonths={2} />
        <Flex flexDirection="row" justifyContent="space-between" margin="6x" marginTop="3x">
          <Button
            onClick={handleClearDates}
            type="button"
            width={180}
            paddingY="2x"
            data-qa-id="clear-selection-button"
            aria-label="Clear selection"
            size="small"
          >
            {i18n._('Reset dates')}
          </Button>
          <Button
            onClick={() => setShowCalendar(false)}
            type="button"
            width={180}
            variant="primary"
            paddingY="2x"
            data-qa-id="save-selection-button"
            data-testid="save-selection-button"
            aria-label="Save dates"
            size="small"
          >
            {i18n._('Save dates')}
          </Button>
        </Flex>
      </Box>
    );
  };
  return (
    <LabelAndErrorFormGroup
      {...other}
      useLabelElementAsCaption={false}
      validationError={newCalendarInteraction ? undefined : other.validationError} // phasing out popovers in favour of inline errors
      validationType={validationType}
    >
      {(formGroupProps): JSX.Element => (
        <DatePickerStylingWrapper
          data-testid="multi-date-auto-fill-picker"
          className={className}
          styleAsError={!!(other.validationError || localError)}
          newCalendarInteraction={newCalendarInteraction}
          isMobileBrowser={isMobileBrowser}
          ref={isMobileBrowser ? null : calendarWrapperRef}
        >
          {!showTwoInputs && renderInput(other.validationError || localError)}
          {showTwoInputs && renderTwoInputs(other.validationError)}
          {showCalendar && isMobileBrowser && renderMobile(formGroupProps)}
          {showCalendar && !isMobileBrowser && renderDesktop(formGroupProps)}
        </DatePickerStylingWrapper>
      )}
    </LabelAndErrorFormGroup>
  );
}

export default MultiDateAutoFillPicker;
