import usePageContext from '@rover/react-lib/src/context/usePageContext';
import { selectors as experimentsSelectors } from '@rover/react-lib/src/redux/ducks/experiments.duck';
import { FrontendExperiment } from '@rover/rsdk/src/apiClient/latest';
import { useFrontendExperiment } from '@rover/rsdk/src/modules/Data/Core/FrontendExperiment';
import getPath from '@rover/shared/js/utils/getPath';

import useDucks from './useDucks';

export type ExperimentsData = {
  experiments: FrontendExperiment[];
  isInExperimentGroup: (arg0: string, arg1: string) => boolean;
  isControl: (arg0: string) => boolean;
  isVariant: (arg0: string) => boolean;
  isCustomVariantGroup: (arg0: string, arg1: string) => boolean;
};

const selector = (state): ExperimentsData => {
  return {
    experiments: experimentsSelectors.getExperiments(state),
    isInExperimentGroup: experimentsSelectors.isInExperimentGroup(state),
    isControl: experimentsSelectors.isControl(state),
    isVariant: experimentsSelectors.isVariant(state),
    isCustomVariantGroup: experimentsSelectors.isCustomVariantGroup(state),
  };
};

const useExperimentsShim = (): ExperimentsData => {
  const { isReactQueryRoute } = usePageContext();
  const path = getPath();
  const { data, isInExperimentGroup, isControl, isVariant, isCustomVariantGroup } =
    useFrontendExperiment(
      {
        enabled: isReactQueryRoute,
      },
      { path }
    );
  const experiments = data?.results ?? [];
  const { data: selectedData } = useDucks({ selector });

  if (isReactQueryRoute) {
    return {
      experiments,
      isInExperimentGroup,
      isControl,
      isVariant,
      isCustomVariantGroup,
    };
  }

  return selectedData;
};

export default useExperimentsShim;
