/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, ReactNode, useCallback, useEffect } from 'react';

import { Box, Button, Heading, Text } from '@rover/kibble/core';
import { DSTokenMap } from '@rover/kibble/styles';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { isMobileBrowser } from '@rover/rsdk/src/modules/Network/userAgent';

import { ERROR_TYPES, useOverlayContext } from './BirthdayDatePickerContext';

export const Overlay: FC<{
  classNames: Record<string, string>;
  selectedDay?: Date;
  children: ReactNode;
}> = ({ children, classNames, selectedDay, ...props }) => {
  const { i18n } = useI18n();
  const isMobile = isMobileBrowser();
  const { error, setError, handleClose } = useOverlayContext();

  useEffect(() => {
    // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
    const contactSitterEl = document.body;
    if (contactSitterEl && isMobile) {
      contactSitterEl.style.overflowY = 'hidden';
      contactSitterEl.style.touchAction = 'none';
    }

    return () => {
      contactSitterEl.style.overflowY = 'scroll';
      contactSitterEl.style.touchAction = 'auto';
    };
  });

  const handleClick = useCallback(() => {
    if (!selectedDay) {
      setError(ERROR_TYPES.EMPTY);
    } else {
      setError(ERROR_TYPES.NO_ERROR);
      handleClose();
    }
  }, [selectedDay]);

  const handleOutsideClose = useCallback((evt) => {
    evt.stopPropagation();
  }, []);

  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay} onClick={handleClose}>
        <div className="DayPickerInput-Overlay-Container" onClick={handleOutsideClose}>
          {isMobile && (
            <Heading mt="8x" textAlign="center">
              {i18n._('Birthday')}
            </Heading>
          )}
          {children}
          {isMobile && (
            <Box padding="4x">
              {error === ERROR_TYPES.EMPTY && (
                <Box mb="6x">
                  <Text textColor="error" size="100" data-qa-id="select-date-error">
                    {i18n._('Select date of birth')}
                  </Text>
                </Box>
              )}
              <Button
                data-qa-id="button-save-dates"
                variant="primary"
                size="small"
                fullWidth
                height={DSTokenMap.SPACE_8X}
                padding={DSTokenMap.SPACE_0X}
                onClick={handleClick}
              >
                {i18n._('Save dates')}
              </Button>
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};
